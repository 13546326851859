import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from "react";
import Collapsible from 'react-collapsible';
import { NavBar, PageHeader, CustomButton } from "../components/Common"
import './Page.scss';
import { api_url } from "../App"


const SubmitAuthError = (props) => {
    const [collapsible, setCollapsible] = useState({ itemTop: false, itemMiddle: false, itemBottom: false });

    const [report_token, setReportToken] = useState( props.location.state && props.location.state.report_token ? props.location.state.report_token : "")

    console.log(report_token)

    return (
        <IonPage className="selectable">
            <NavBar />
            <IonContent>
                <PageHeader label="Don't panic 😌" />
                <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>
                    <IonRow>
                        <IonCol>         

                            <p style={{fontWeight:700}}>There's a few things you can check:</p>

                            <p> - Did you enter your UTR number correctly?</p>

                            <p> - Are your HMRC login details correct? If you're not sure then <a href="https://help.untied.io/article/59-submitting-your-tax-return" target="_new">use untied express submit</a>.</p>

                            <p> - Did you register for self-assessment very recently? It can take a day or two before you can file. We saved your data, so you can try again later.</p>

                            <p> - Is there a chance you aren’t registerd for self-assessment with HMRC? If so, <a href="mailto:help@untied.io">email us</a>, we can help </p>
                           
                            <p style={{fontWeight:700}}>You can <a href={`https://pdfe1.untied.io/render_pdf.php?u=https://${api_url}/tax/viewReturnReport.php?t=${report_token}`}>view your draft tax return here</a> to see the details you entered.</p>



                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-sm="12">
                            <CustomButton
                                onClick={(e) => {
                                    props.history.push('/page/TaxFiling', {addReturn: true, processingSubmission: true})
                                    e.preventDefault()
                                }}
                                label="Check my details and try again"
                                big
                                style={{width:"200px!important", maxWidth:"200px!important"}}
                            />
                        </IonCol>

                    </IonRow>

                    <IonRow>
                        <IonCol class="mt-3">
                            <p>
                            <span style={{fontWeight:700}}>I checked all those things and it’s not it 😱</span></p>
                            <p>Don’t you worry, untied’s team of tax experts is here to help you. Send us an email to <a href="mailto:help@untied.io">help@untied.io</a> and we’ll help you. 
                            </p>
                      
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default SubmitAuthError;

import {
  IonContent,
  IonPage,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  IonLoading
} from '@ionic/react';
import React, { Component } from "react";
import { getTaxYears, getTaxCalc, NavBar, PageHeader, CustomButton, YearSelector } from "../components/Common"
import { api_url } from "../App"
import { Mixpanel } from "./mixpanel";
import Collapsible from 'react-collapsible';
import './Page.scss';


class Employment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      showHelp: false,
      years: [],
      selectedYear: "-1",
      selectedYearData: {},
      employments: [],
      taxCalc: {},
      itemTop: false
    };
  }

  componentDidMount() {
    Mixpanel.track("loaded_employment");
    getTaxYears((years, yearInfo) => {
      this.setState({ years, selectedYear: yearInfo.id, selectedYearData: yearInfo },
        () => {
          this.getEmployment();
          getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
        }
      );
    });
  }

  getEmployment() {
    var timeStamp = Math.floor(Date.now() / 1000);
    const token = localStorage.getItem("token");
    fetch(`https://${api_url}/getTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=employment`, {
      method: "GET",
      headers: { token: token }
    })
      .then(res => res.json())
      .then(json => {

        var new_data = {
          emp_name: "",
          paye_ref: "",
          pay: "",
          tax: ""
        }

        const data = JSON.parse(json.data)

        if (data != null) {

          if (data.length > 0) {
            new_data = {
              emp_name: data[0].box_5,
              paye_ref: data[0].box_4,
              pay: data[0].box_1,
              tax: data[0].box_2,
            }
          }
        }

        //this.setState({ ...new_data })
        this.setState(Object.assign(this.state, new_data));
      })
  }

  saveEmployment() {
    let timeStamp = Math.floor(Date.now() / 1000);
    const token = localStorage.getItem("token");

    const include = (this.state.emp_name + this.state.paye_ref + this.state.pay + this.state.tax) !== ""

    let payload = [{
      include: include,
      box_5: this.state.emp_name,
      box_4: this.state.paye_ref,
      box_1: this.state.pay,
      box_2: this.state.tax,
    }];

    fetch(`https://${api_url}/saveTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=employment`, {
      method: "POST",
      headers: { token: token },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(json => {
        Mixpanel.track("saved_employment");
        this.setState({ showSaved: true, showLoading: false });
        this.getEmployment();
      })
  }

  handleYearChange = (event) => {
    const yearElement = this.state.years.find(element => {
        return element.id === event.target.value
    })
    Mixpanel.track("changed_year", yearElement);

    this.setState({ selectedYear: event.target.value, selectedYearData: yearElement }, () => {
        this.getEmployment();
        getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
    });
  } 

  render() {
    return (
      <IonPage className="selectable">
        <NavBar label="2C. Employment" />

        <IonContent>
          <PageHeader label="Employment" />

          <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>

            <IonRow>
              <IonCol>
                <Collapsible
                  trigger={(
                    () => (
                      <div className="mt-0">
                        <p>untied lite supports one employment - where you get a payslip and tax is deducted. Your employer will send you a P60 form in April/May, or a P45 if you left during the year.
If you were employed you’ll need the numbers from these forms.  <span className="strong-text fake-href">Show me where</span>. 
If you only worked as self-employed this tax year you can skip this step.
                               
                        </p>
                      </div>
                    )
                  )()}
                  containerElementProps={{ id: "itemTop" }}
                  onOpen={() => {
                    this.setState({ itemTop: true });
                  }}
                  onClose={() => {
                    this.setState({ itemTop: false });
                  }}
                >
                  <div className="p-60"></div>

                </Collapsible>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm="3" />
              <IonCol size-sm="6">
                <CustomButton
                  linkTo="/page/Profile"
                  label="Doesn't apply to me"
                />
              </IonCol>
              <IonCol size-sm="3" />
            </IonRow>

            <IonRow>
              <IonCol>
                <YearSelector onYearChange={(event) => this.handleYearChange(event)} years={this.state.years} selectedYear={this.state.selectedYear} />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Employer's name (from P60):</IonLabel>
                  <IonInput value={this.state.emp_name}
                    onIonChange={e => this.setState({ emp_name: e.detail.value })}
                    placeholder="" inputmode="text" type="text" />
                </IonItem >
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Employer's PAYE reference:</IonLabel>
                  <IonInput value={this.state.paye_ref}
                    onIonChange={e => this.setState({ paye_ref: e.detail.value })}
                    placeholder="From P60" inputmode="text" type="text" />
                </IonItem >
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Pay in this employment:</IonLabel>
                  <IonInput value={this.state.pay}
                    onIonChange={e => this.setState({ pay: e.detail.value })}
                    placeholder="From P60" inputmode="numeric" type="number" />
                </IonItem >
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Tax in this employment:</IonLabel>
                  <IonInput value={this.state.tax}
                    onIonChange={e => this.setState({ tax: e.detail.value })}
                    placeholder="From P60" inputmode="numeric" type="number" />
                </IonItem >
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm="3" />
              <IonCol size-sm="6" className="text-center">
                <CustomButton
                  onClick={() => {
                    this.setState({ showLoading: true });
                    this.saveEmployment();
                  }}
                  label="Save employment"
                />
              </IonCol>
              <IonCol size-sm="3" />
            </IonRow>

            <IonRow>
              <IonCol>
                <p style={{color:'#3D3D3D', fontSize:14}}>If you made money in other ways, for example, renting out a property, you need to upgrade to <span className="strong-text">untied Pro</span>.
                  Contact us at <a href="mailto:upgrade@untied.io">upgrade@untied.io</a> about a special offer for untied Pro today.</p>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonAlert
            isOpen={this.state.showHelp}
            onDidDismiss={() => this.setState({ showHelp: false })}
            header={'Help'}
            subHeader={this.state.helpTitle}
            message={this.state.helpText}
            buttons={[{
              text: 'OK',
              role: 'OK',
              cssClass: 'shadow text-center col-12'
            }]}
          />

          <IonAlert
            isOpen={this.state.showSaved}
            onDidDismiss={() => this.setState({ showSaved: false })}
            header={'Saved'}
            subHeader={''}
            message={'Your employment info has been saved'}
            buttons={[{
              text: 'OK',
              role: 'OK',
              cssClass: 'shadow text-center col-12',
              handler: () => {
                this.props.history.replace('/page/Profile');
              }
            }]}
          />

          <IonLoading
            isOpen={this.state.showLoading}
            onDidDismiss={() => this.setState({ showLoading: false })}
            message={'Please wait...'}
            duration={5000}
          />
        </IonContent>
      </IonPage >
    );
  }
}

export default Employment;

import React, { useState } from 'react';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonNote } from '@ionic/react';
import { cashOutline, cashSharp, carOutline, carSharp, businessOutline, businessSharp, personCircleOutline, personCircleSharp, documentOutline, documentSharp, helpCircleOutline, helpCircleSharp, exitOutline, exitSharp, homeOutline, homeSharp, calendarOutline, calculatorSharp, briefcaseOutline, briefcaseSharp, cardOutline, cardSharp, medkitOutline } from 'ionicons/icons';
import { withRouter, useLocation } from 'react-router-dom';
import './Menu.css';

const appPages = [
  {
    title: 'Home',
    name: 'Home',
    url: '/page/Home',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    expandable: false,
    level: 1,
    nestedLevels: []
  },
  {
    title: 'Earnings and expenses',
    name: 'EarningsExpenses',
    url: '/page/EarningsExpenses',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
    expandable: true,
    level: 1,
    nestedLevels: [
      {
        title: 'Self-employment',
        name: 'SelfEmployment',
        url: '/page/SelfEmployment',
        iosIcon: briefcaseOutline,
        mdIcon: briefcaseSharp,
        expandable: true,
        level: 2,
        nestedLevels: [
          {
            title: 'Mileage',
            name: 'Mileage',
            url: '/page/Mileage',
            iosIcon: carOutline,
            mdIcon: carSharp,
            expandable: false,
            level: 3,
            nestedLevels: []
          }
        ]
      },
      {
        title: 'Employment',
        name: 'Employment',
        url: '/page/Employment',
        iosIcon: businessOutline,
        mdIcon: businessSharp,
        expandable: false,
        level: 2,
        nestedLevels: []
      }
    ]
  },
  {
    title: 'Profile',
    name: 'Profile',
    url: '/page/Profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
    expandable: false,
    level: 1,
    nestedLevels: []
  },
    {
        title: 'Tax filing',
        name: 'TaxFiling',
        url: '/page/TaxFiling',
        iosIcon: documentOutline,
        mdIcon: documentSharp,
        expandable: false,
        level: 1,
        nestedLevels: []
    },
    {
        title: 'Help',
        name: 'Help',
        url: '/page/Help',
        iosIcon: helpCircleOutline,
        mdIcon: helpCircleSharp,
        expandable: false,
        level: 1,
        nestedLevels: []
    },
    /*{
        title: 'Pay HMRC',
        name: 'HMRCPayment',
        url: '/page/HMRCPayment',
        iosIcon: cardOutline,
        mdIcon: cardSharp,
        expandable: false,
        level: 1,
        nestedLevels: []
    },*/
    {
        title: 'Subscription',
        name: 'Plan',
        url: '/page/Plan',
        iosIcon: calendarOutline,
        mdIcon: calculatorSharp,
        expandable: false,
        level: 1,
        nestedLevels: []
        },
    {
        title: 'COVID-19 support',
        name: 'Covid',
        url: '/page/cov19',
        iosIcon: medkitOutline,
        mdIcon: medkitOutline,
        expandable: false,
        level: 1,
        nestedLevels: []
        },
    {
        title: 'Logout',
        name: 'Logout',
        url: '/logout',
        iosIcon: exitOutline,
        mdIcon: exitSharp,
        expandable: false,
        level: 1,
        nestedLevels: []
    }

];

const Menu = () => {
    const location = useLocation();
    const selectedPage = location.pathname.replace("/page/","").split("/")[0];

    const evaluateCurrentLocation = (routes: Array<string>) => {
    for (let i = 0; i < routes.length; i++) {
      if (selectedPage.toLowerCase() === routes[i]) {
        return true;
        break;
      }
    }
  };

  const defaultLocation = {
    secondLevel: evaluateCurrentLocation(["mileage", "selfemployment", "employment"]),
    thirdLevel: evaluateCurrentLocation(["mileage"])
  };

  const [expandConfig, setExpandConfig] = useState({
    secondLevel: defaultLocation.secondLevel,
    thirdLevel: defaultLocation.thirdLevel
  });

  const [isDirty, setIsDirty] = useState(false);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <div className="banner-blue" />
          </IonListHeader>
          <IonNote className="notranslate text-center">untied lite</IonNote>
          {appPages.map((appPage, index) => (
            <div key={index}>
              <IonItem className={selectedPage === appPage.name ? 'selected' : ''}
                href={appPage.url}
                routerDirection="forward"
                detail={false}
                lines="none">
                <IonIcon slot="start" color="primary" icon={appPage.iosIcon} />
                <IonLabel className="ion-text-wrap">{appPage.title}</IonLabel>
                {false && appPage.expandable &&
                  <div className="next-menu-level-expander text-center" onClick={(e) => {
                    e.preventDefault();
                    setExpandConfig({
                      secondLevel: isDirty ? !expandConfig.secondLevel : !defaultLocation.secondLevel,
                      thirdLevel: false
                    });
                    setIsDirty(true);
                  }}>
                    <i className={isDirty ? (expandConfig.secondLevel === true ? "expanded" : "collapsed") : (defaultLocation.secondLevel === true ? "expanded" : "collapsed")} />
                  </div>}
              </IonItem>
              {appPage.expandable && appPage.nestedLevels.map((nestedItem, nestedIndex) => (
                <div key={nestedIndex}>
                  {/*
                              <IonItem hidden={isDirty ? !expandConfig.secondLevel : !defaultLocation.secondLevel}
*/}
                  <IonItem hidden={false}
                    className={selectedPage === nestedItem.name ? 'sub-menu selected' : 'sub-menu'}
                    href={nestedItem.url}
                    routerDirection="forward"
                    detail={false}
                    lines="none">
                    <IonIcon slot="start" color="primary" icon={nestedItem.iosIcon} />
                    <IonLabel className="ion-text-wrap">{nestedItem.title}</IonLabel>
                    {false && nestedItem.expandable &&
                      <div className="next-menu-level-expander text-center" onClick={(e) => {
                        e.preventDefault();
                        setExpandConfig({
                          secondLevel: true,
                          thirdLevel: isDirty ? !expandConfig.thirdLevel : !defaultLocation.thirdLevel
                        });
                        setIsDirty(true);
                      }}>
                        <i className={isDirty ? (expandConfig.thirdLevel === true ? "expanded" : "collapsed") : (defaultLocation.thirdLevel === true ? "expanded" : "collapsed")} />
                      </div>}
                  </IonItem>
                  {nestedItem.expandable && nestedItem.nestedLevels.map((nestedItemInner, nestedIndexInner) => (
                    //<IonItem key={nestedIndexInner} hidden={isDirty ? !expandConfig.thirdLevel : !defaultLocation.thirdLevel} className={selectedPage === nestedItemInner.name ? 'sub-sub-menu selected' : 'sub-sub-menu'} href={nestedItemInner.url} routerDirection="forward" detail={false} lines="none">
                    <IonItem key={nestedIndexInner} hidden={false} className={selectedPage === nestedItemInner.name ? 'sub-sub-menu selected' : 'sub-sub-menu'} href={nestedItemInner.url} routerDirection="forward" detail={false} lines="none">

                      <IonIcon slot="start" color="primary" icon={nestedItemInner.iosIcon} />
                      <IonLabel className="ion-text-wrap">{nestedItemInner.title}</IonLabel>
                    </IonItem>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </IonList>
      </IonContent>
    </IonMenu >
  );
};

export default withRouter(Menu);

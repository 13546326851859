import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import React from "react";
import { NavBar, PageHeader } from "../components/Common"
import './Page.scss';

const Help = () => {
  return (
    <IonPage className="selectable">
      <NavBar label="Help" />

      <IonContent>
        <PageHeader label="Help" />
        <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
          <IonRow>
            <IonCol>


              <p>We have added an explanation at the top of each section to help guide you through the whole process, but if you need additional help...</p>

              <h6>1.	Check out all the <a href="https://help.untied.io/category/58-gig-app"><span className="strong-text">FAQs</span></a> on our website</h6>
              <h6>2.	Email us on <a href="mailto:contact@untied.io"><span className="strong-text">contact@untied.io</span></a></h6>
              <h6>3.	Use our contact form <a href="https://help.untied.io/contact?gig"><span className="strong-text">here</span></a></h6>

              <h6 className="mt-3"><span className="strong-text">Things that are also good to know:</span></h6>

              <h6 className="mt-3">untied is safe and secure</h6>
              <h6>- untied’s software is <span className="strong-text">recognised by HMRC – the UK’s tax authority.</span></h6>
              <h6>- untied is also <span className="strong-text">regulated by the Financial Conduct Authority (FRN 910169)</span> and supervised by the Institute of Tax.</h6>

              <h6 className="mt-3"><span className="strong-text">Completing a tax return</span></h6>

              <h6 className="mt-3">- The Tax Filing in the UK is called a Tax Return (don't confuse it with a refund where you get money back).</h6>
              <h6>- <span className="strong-text">Not everyone needs to file a tax return.</span></h6>
              <h6>- You will need to file if you <span className="strong-text">earned more than £1,000 through self-employment.</span></h6>
              <h6>- You need to make sure <span className="strong-text">you are registered as self-employed with HMRC.</span> If you are not yet registered, email us, and we'll help you.</h6>
              <h6>- Your taxes are for a period that <span className="strong-text">starts on 6 April and ends the following 5 April.</span> You then have until the <span className="strong-text">next 31 January to file.</span></h6>

              <h6 className="mt-3"><span className="strong-text">Using untied lite</span></h6>



              <h6 className="mt-3">- untied lite is designed for people with <span className="strong-text">flexible income</span> generated <span className="strong-text">through self-employed platforms</span>, like Deliveroo and Uber. It will help you <span className="strong-text">submit your tax return quickly.</span></h6>
              <h6>- Some people may need untied Pro, which offers more features, such as linking bank accounts for tagging income and expenses, logging journeys automatically and entering other income sources (eg property income).</h6>

              <h6 className="mt-3">If you have any other questions or feedback, we’d love to hear from you. <a href="mailto:contact@untied.io"><span className="strong-text">Contact us</span></a>, and we'll help you out.</h6>


            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Help;

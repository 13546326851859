import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import React, { Component } from "react";

import './Page.scss';
import Collapsible from 'react-collapsible';
import {CustomButton, NavBar, PageHeader} from "../components/Common"

class EarningsExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemTop: false,
      itemBottom: false
    }
  }


  render() {
    return (
      <IonPage className="selectable">
        <NavBar label="Earnings and Expenses"/>

        <IonContent>
            <PageHeader label="Earnings and expenses"/>

            <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>

            <IonRow>
              <IonCol>
                <p>Enter your income, work related expenses and mileage.</p>
                <p>You can enter your earnings in two ways:</p>
              
                <p>- Self-employment</p>
                <p>- Employment - this may not apply to everyone</p>

                <p>&nbsp;</p>

                <h5>Are you looking for automatic mileage tracking? 👀</h5>

                <p>This feature is part of untied Pro, you would need to upgrade. Contact us at <a href="mailto:upgrade@untied.io">upgrade@untied.io</a> for a special offer. </p>

                
              </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size-sm="3"/>
                <IonCol size-sm="6" className="ion-align-self-start text-center">
                    <CustomButton
                        linkTo="/page/SelfEmployment"
                        label="Next"
                    />
                </IonCol>
                <IonCol size-sm="3"/>
            </IonRow>
          </IonGrid>

        </IonContent>
      </IonPage >
    );
  }
}

export default EarningsExpenses;

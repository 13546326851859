import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Mixpanel } from "./mixpanel";
import { IonAlert, IonGrid, IonRow, IonCol, IonCardContent, IonPopover, IonButton } from '@ionic/react';
import { api_url } from "../App"
import './Page.scss';
import { TranslateIcon } from "../components/Common"
import { Footer } from "../components/Common";

const partnerID = "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      agree: false,
      doingMagiclink: false,
      devserver: false,
      secretcode: "",
      loggedin: false,
      wrongPlanRedirect:false,
      goBack: false,
      heading_text: (<span>The UK's personal tax app</span>),
      placeholder: "Check your inbox",
      helptext: "",
      showCheckout: false,
      offercode: "",
      target: 'Home',
      rememberMe: false,
      offer_extension: false,
      showMessage:false,
      welcomeTitle:"Welcome"
    };
    this.handleLoad = this.handleLoad.bind(this)
  }
  handleLoad() {
    //focus the pesky input ... 
    const input = document.getElementById('username')
    if (input) {
      setTimeout(() => input.focus(), 100);
      input.addEventListener('keyup', (event) => { if (event.keyCode === 13) { this.doLogin(true, false) } })
    }
  }

  componentDidMount() {

    if (this.props.match != null) {

      if (this.props.match.params.token) {
        let token = this.props.match.params.token;
        let email = this.props.match.params.email;
        this.setState({username: email, secretcode: token})
        this.auth(email, token);
      }

      if (this.props.match.params.logout) {
        localStorage.removeItem("selectedYear");
        localStorage.removeItem("token");
        //localStorage.removeItem('userid');
      }

      const savedEmail = localStorage.getItem('rememberEmail');
      if (savedEmail) {
        this.setState({ username: savedEmail, rememberMe: true });
      }


    }

    //get user's IP
    fetch("https://api.ipify.org?format=json")
      .then(res => res.json())
      .then(json => {
        localStorage.setItem("IP_ADDRESS", json.ip);
      });

    window.addEventListener('load', this.handleLoad);

    var urlParams = new URLSearchParams(window.location.search);
    const offercode = urlParams.get('offercode')
    if (offercode != null) {
      this.setState({ offercode })
    }
    const target = urlParams.get('target')
    if (target != null) {
      console.log('target', target)
      localStorage.setItem("target", target);
      this.setState({ target })
    }


  }


  _updateUsername = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  doLogin(usemagiclink = true, register = true) {
    if (this.state.username === "") {
      this.setState({
        showMessage: true, messageTitle: 'Error',
        messageSubTitle: 'Missing email', messageText: "Please enter your email address."
      })
      return;
    }

    if (!this.validateEmail(this.state.username)) {
      this.setState({
        showMessage: true, messageTitle: 'Error',
        messageSubTitle: 'Invalid email', messageText: "Please check your email address is correct."
      })
      return;
    }

    if (this.state.rememberMe) {
      localStorage.setItem('rememberEmail', this.state.username)
    } else {
      localStorage.removeItem('rememberEmail')
    }

    if (usemagiclink) {
      var timeStamp = Math.floor(Date.now() / 1000);
      Mixpanel.track("pressed_get_started");
      this.setState({ doingMagiclink: true });
      //this.setState({ heading_text: "Check your email to continue" });
      const username = encodeURIComponent(this.state.username)
      const offercode = this.state.offercode
      fetch(
        `https://${api_url}/userFromEmail.php?email=${username}&ts=${timeStamp}&app=gig&ref=${window.location.href}&offercode=${offercode}`,
        {
          method: "GET",
          headers: { partnerId: partnerID }
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json.status === "success") {
            // Mixpanel.track("used_magic_link");
            Mixpanel.alias(json.guid);
            this.setState({
              showMessage: false, messageTitle: register ? 'Welcome' : 'Welcome back',
              welcomeTitle: register ? 'Welcome' : 'Welcome back',
              messageSubTitle: '', messageText: "We sent a link to the email address entered.<br/><br/>Please click there to log in."
            })

          } else {
            this.setState({
              showMessage: true, messageTitle: 'Unauthorised',
              messageSubTitle: 'There was a problem', messageText: "Sorry, there seems to be a problem logging in"
            })
          }
        });
    } else {
      // want to use password ...
      this.setState({ usePassword: true, goBack: true });
      Mixpanel.track("pressed_use_password");
    }
  }

  _doSecretCode() {
    this.auth(this.state.username, this.state.secretcode);
  }

  auth(email, token, usePassword = false) {

    var timeStamp = Math.floor(Date.now() / 1000);
    var body = JSON.stringify({ email: email, token: token });
    //if (this.state.usePassword) {
    if (usePassword) {
      body = JSON.stringify({ email: email, password: token });
    }
    fetch(`https://${api_url}/getToken.php?ts=${timeStamp}`, {
      method: "POST",
      body: body,
      headers: { partnerId: partnerID }
    })
      .then(res => res.json())
      .then(json => {

        console.log(json)

        if (json.status === "success") {
          //alert('successfully logged in!')
          //localStorage.setItem('guid',json.guid);
          //localStorage.setItem('userid', json.id);
          localStorage.setItem("token", json.token);
          localStorage.setItem("admin_token", json.token);
          localStorage.setItem("admin", json.admin);
          localStorage.setItem("username", email);
          localStorage.setItem("logged_in_as", "");
          // debugger

          if(!json.in_trial){
            if(json.plan == '2' && json.subscription_active =="Y"){
              this.setState({wrongPlanRedirect:true})
            }
            else{
              this.setState({ loggedin: true });
            }

          }
          else{
            this.setState({ loggedin: true });
          }

          // this.setState({ loggedin: true });
          Mixpanel.identify(json.guid);
          Mixpanel.track("log_in");

          Mixpanel.people.set({
            $email: email,
            dev: process.env.NODE_ENV !== "production"
          });
        } else {

          if (json.info && json.info === 'trial_expired') {
            Mixpanel.identify(json.guid);
            this.setState({
              showCheckout: true, checkoutEmail: email, offercode: json.offercode,
              showMessage: false, messageTitle: 'Your trial has expired', heading_text: "Your trial has expired",
              messageSubTitle: '', messageText: "Sorry, your 30 day trial has expired. Please purchase a plan.",
              offer_extension: json.offer_extension,
              guid: json.guid
            })
            return;
          }


          if (json.info && json.info === 'subscription_invalid') {
            Mixpanel.identify(json.guid);
            this.setState({
              showCheckout: true, checkoutEmail: email, offercode: json.offercode,
              showMessage: false, messageTitle: 'Your subscription has expired', heading_text: "Your subscription has expired",
              messageSubTitle: '', messageText: "Sorry, your subscription expired. Please purchase a plan.",
              offer_extension: json.offer_extension,
              guid: json.guid
            })
            return;
          }

          //if we didn't use password, now try with password ... 
          if (!usePassword) {
            this.auth(email, token, true);
            return;
          }

          if (json.info === 'too_many_attempts') {

              //otherwise we error .. 
              this.setState({
                showMessage: true, messageTitle: 'Unauthorised',
                messageSubTitle: '', messageText: "There have been too many filed attempts to log in. Please wait 5 minutes and try again."
              })

          } else {

            //otherwise we error .. 
            this.setState({
              showMessage: true, messageTitle: 'Unauthorised',
              messageSubTitle: '', messageText: "Sorry, I was not able to log you in."
            })

          }

          Mixpanel.track("Unsuccessful login");
        }
      });
  }

  yyyymmdd() {
    function twoDigit(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var now = new Date();
    return (
      "" +
      now.getFullYear() +
      "-" +
      twoDigit(now.getMonth() + 1) +
      "-" +
      twoDigit(now.getDate())
    );
  }

  doPurchase(iap_item) {

    const body = JSON.stringify({
      email: this.state.checkoutEmail,
      start_date: this.yyyymmdd(),
      plan: "3",
      sub_sku: (iap_item === 630467 || iap_item === 630445) ? 'untiedy1' : 'untiedm1',
      store: "untied",
      app: 'gig'
    });


    let timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${api_url}/addUserSubscription.php?ts=${timeStamp}&duration=year`,
      {
        method: "POST",
        body: body,
        headers: { partnerId: partnerID }
      }
    )
      .then(res => res.json())
      .then(json => {

        //re-run auth - this should now log us in if all successful ... 
        this._doSecretCode()

      });

  }

  waitForPaddleFrame() {
    if (document.getElementsByClassName('paddle-frame').length > 0) {
      document.getElementsByClassName('paddle-frame')[0].style.position = 'fixed';
      console.log('found it')
      return;
    }
    else {
      setTimeout(() => {
        console.log('looking again')
        this.waitForPaddleFrame();
      }, 500);
    }
  }

  openCheckout = (sku) => {

    let coupon = ""
    if (this.state.offercode) {
      coupon = this.state.offercode.toLowerCase() != 'deliveroo15' ? this.state.offercode : ""
    }
    const email = this.state.checkoutEmail
    window.Paddle.Checkout.open({
      product: sku, email: email, coupon: coupon,
      successCallback: () => {
        // setup plan .. 
        this.doPurchase(sku)
      },
      closeCallback: () => { this.setState({ ready: true }) }
    });

    this.waitForPaddleFrame()
  }

  extendTrial() {
    Mixpanel.track("extended_trial");
    let timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${api_url}/extendTrial.php?ts=${timeStamp}&duration=year`,
      {
        method: "POST",
        body: JSON.stringify({guid:this.state.guid}),
        headers: { partnerId: partnerID }
      }
    )
      .then(res => res.json())
      .then(json => {

        //re-run auth - this should now log us in if all successful ... 
        console.log(json);
        this._doSecretCode()

      });
  }

  wrongPlanPopover(){
    return (
      <IonPopover
            className = "wrong-plan"
            isOpen={this.state.wrongPlanRedirect}
            onDidDismiss={() => this.setState({wrongPlanRedirect:false})}
            backdropDismiss = {false}
        >
        <IonGrid style = {{"minWidth": 300, maxWidth: 600}}>

          <IonRow>
            <IonCol style ={{margin: "0px 15px", paddingTop: 20}} >
              <h3 style = {{fontWeight: "800", color: "#3f90b0", marginBottom: 5}}>Confirm your destination</h3>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol style ={{margin: "0px 15px", }} >
              <p>We can see you're trying to log in to untied lite.</p>
              <p>Your email is already associated with a trial of untied Pro. If you log into lite, certain information you've entered will not be visible to you.</p>
              <p>untied Pro includes the mobile app, lets you link bank accounts, logs mileage, supports more income sources and has more automatic optimisation.</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol style ={{textAlign: "center"}} >
              <IonButton 
                className = "ion-button-money-inverse"
                style = {{width: 200}}
                onClick = {()=> {
                  this.setState({
                    wrongPlanRedirect:false,
                    loggedin: true
                    })
                }}
              >
                Continue to lite
              </IonButton>
            </IonCol>

            <IonCol style ={{textAlign: "center"}} >
              <IonButton
                style = {{width: 200}}
                className = "ion-button-money"
                href="https://app.untied.io/"
              >
                Take me to pro
              </IonButton>

            </IonCol>
          </IonRow>
        </IonGrid>
        </IonPopover>  
        )
  }

  doBottomView() {
    if (this.state.showCheckout) {

      const isDeliveroo = this.state.offercode && this.state.offercode.toLowerCase() === 'deliveroo15';

      return (
        <IonGrid style={{ width: '100%', marginTop: 20, paddingTop: 0, textAlign: 'center' }}>
          <IonRow>
            <IonCol><h2 style={{ marginTop: 0, paddingTop: 0, color: "rgba(209,0,73,1.0)", fontWeight: 700 }}>{this.state.messageTitle}</h2></IonCol>
          </IonRow>



          <IonRow>
            <IonCol><p>To continue using untied and get your tax return done please purchase a plan. Your data is safe and will be restored when you start your plan so that you can continue where you left off.</p></IonCol>
          </IonRow>

          {isDeliveroo && (
            <IonRow>
              <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

                <div
                  onClick={() => this.setState({ btn1Selected: true, btn2Selected: false }, () => this.openCheckout(630445))}
                  //style={{ ...styles.buttonContainer, borderColor: this.state.btn1Selected ? "#ff015a" : 'white' }}
                  style={styles.paddleButton}
                >

                  <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£15/year</p>

                </div>

                <div style={{ ...styles.offerBadge, width: 110 }}>Deliveroo offer</div>


              </IonCol>
            </IonRow>
          )}

          {!isDeliveroo && (
            <IonRow >

              {/* <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

                <div
                  onClick={() => this.setState({ btn1Selected: false, btn2Selected: true }, () => this.openCheckout(630468))}
                  style={styles.paddleButton}
                >

                  <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£4.99/month</p>
                </div>


              </IonCol> */}

              <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

                <div
                  onClick={() => this.setState({ btn1Selected: true, btn2Selected: false }, () => this.openCheckout(630467))}
                  style={styles.paddleButton}
                >

                  <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£49.99/year</p>
                </div>

                {/* <div style={styles.offerBadge}>Best value</div> */}


              </IonCol>

            </IonRow>
            
          )}

          {this.state.offer_extension && (
            <IonRow>
                <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{textDecoration:'underline', cursor:'pointer'}} onClick={()=>this.setState({showExtendTrialMessage:true})}>Get another 7 days' free trial</p>
                </IonCol>

                <IonAlert
                  isOpen={this.state.showExtendTrialMessage}
                  onDidDismiss={() => this.setState({ showExtendTrialMessage: false })}
                  header={'Extend your trial'}
                  subHeader={'Do you want to try untied free for another 7 days?'}
                  message={"As a special one time offer, we'll restore all your data and you can continue right where you left off. A paid subscription will still be required to file your tax return"}
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel' ,
                      cssClass: 'text-center col-5 alert-cancel-button',                
                    },
                    {
                      text: 'Yes please',
                      cssClass: 'text-center col-5',
                      handler: blah => {
                          this.extendTrial()
                      }
                    },
                  ]}
              />

            </IonRow>

            

          )}

        </IonGrid>
      )
    }

    if (!this.state.doingMagiclink && !this.state.usePassword) {
      return (
        <IonGrid>
          <IonRow>
            <IonCol>
              E-mail:<br />
              <TextField
                style={{ marginTop: 10 }}
                autoFocus={true}
                value={this.state.username}
                onChange={this._updateUsername("username")}
                placeholder="Email address"
                margin="none"
                InputProps={{ disableUnderline: true, id: "username" }}
              />
              <br />
              <FormControlLabel control={<Checkbox style={{ color: '#0C90B2', marginLeft: 10 }} checked={this.state.rememberMe} onChange={(event) => this.setState({ rememberMe: event.target.checked })} />} label="Remember me" />
            </IonCol>

          </IonRow>
          <IonRow>

            <IonCol style={{ textAlign: 'center' }}>
              <Button
                style={styles.buttonContainer}
                size="small"
                onClick={() => this.doLogin(true, true)}
              >
                Register
              </Button>
            </IonCol>

            <IonCol style={{ textAlign: 'center' }}>
              <Button
                style={styles.buttonContainer2}
                size="small"
                onClick={() => this.doLogin(true, false)}
              >
                Login
              </Button>
            </IonCol>

          </IonRow>

        </IonGrid>);
    } else {
      let placeholder = "Check your inbox";
      //var infotext = "Setting things up for you. Please wait a second...";
      let field_type = "password";
      // if (this.state.usePassword) {
      //   placeholder = "Password";
      //   //infotext = "Please enter your password:";
      //   field_type = "password";
      // }

      // const {goBack} = this.props.navigation;

      return (
        <IonGrid>

          <IonRow>
            <IonCol>
              <h2 style={{color: "#0C90B2"}}>{this.state.welcomeTitle}</h2>
              <p>We sent a link to the email address entered. Please click there to log in or enter the secret code below:</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              Secret code:<br />
              <TextField
                style={{ marginTop: 10 }}
                placeholder={placeholder}
                type={field_type}
                value={this.state.secretcode}
                onChange={event =>
                  this.setState({ secretcode: event.target.value })
                }
                InputProps={{
                  disableUnderline: true
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: 30 }}>
            <IonCol style={{ textAlign: 'center' }}>
              {this.state.goBack === false ? (
                <Button
                  style={styles.buttonContainer}
                  size="small"
                  onClick={() =>
                    this.setState({
                      doingMagiclink: !this.state.doingMagiclink,
                      goBack: !this.state.goBack,
                      heading_text: "The UK's personal tax app"
                    })
                  }
                >
                  Back
                </Button>
              ) : (
                <Button
                  style={styles.buttonContainer}
                  size="small"
                  onClick={() =>
                    this.setState({
                      usePassword: !this.state.usePassword,
                      goBack: !this.state.goBack
                    })
                  }
                >
                  Back
                </Button>
              )}
            </IonCol>
            <IonCol style={{ textAlign: 'center' }}>
              <Button
                onClick={() => this._doSecretCode()}
                style={styles.buttonContainer2}
              >
                {" "}
                Login{" "}
              </Button>
            </IonCol>

          </IonRow>
        </IonGrid>);
    }
  }

  render() {
    if (this.state.loggedin) {
      const target = this.state.target || localStorage.getItem('target');
      window.location.pathname = '/page/' + target;
      return <div />
    }

    return (
      <IonGrid className="h-100 d-flex app-container-grid login-page-container">
        <IonRow className="w-100">
          <IonCol size="12" className="content-col">
            <IonCardContent className="d-flex align-items-center">
              <div className="translate" style={{ paddingTop: 20 }} >
                <TranslateIcon />
              </div>
              <div className="position-absolute">
                <div className="banner-black" />
                {/* <p className="text-center app-name">
                    {this.state.heading_text}
                  </p> */}
                {this.doBottomView()}
                {this.wrongPlanPopover()}
              </div>

            </IonCardContent>


            <IonAlert
              isOpen={this.state.showMessage}
              onDidDismiss={() => this.setState({ showMessage: false })}
              header={this.state.messageTitle}
              subHeader={this.state.messageSubTitle}
              message={this.state.messageText}
              cssClass="login-alert"
              buttons={[{
                text: 'OK'
              }]}
            /> 

          </IonCol>

          <IonCol size="12" className="footer-col" style={{ backgroundColor: '#3F90B0' }}>
            <Footer />
          </IonCol>

        </IonRow>


      </IonGrid>
    );
  }
}

function scale(val) {
  return val;
}


const styles = {
  root: {
    background: "black"
  },
  card: {
    minWidth: 675
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  buttonContainer: {
    background: "#FFFFFF",
    border: "1px solid #0C90B2",
    "box-sizing": "border-box",
    "border-radius": '4px',
    fontWeight: 700,
    color: '#0C90B2',
    width: 128,
    height: 41,
    fontSize: 16
  },
  buttonContainer2: {
    background: "#0C90B2",
    border: "1px solid #ffffff",
    "box-sizing": "border-box",
    "border-radius": '4px',
    fontWeight: 700,
    color: '#ffffff',
    width: 128,
    height: 41,
    fontSize: 16
  },
  buttonText: {
    color: "#0C90B2",
    textAlign: "center",
    fontWeight: "700"
  },
  itIs: {
    color: "#c7ff00",
    fontSize: 18,
    fontWeight: "500",
    letterSpacing: 0.54,
    textAlign: "center",
    alignSelf: "center"
  },
  paddleButton: {
    display: 'flex',
    background: "#FFFFFF",
    border: "1px solid #0C90B2",
    "box-sizing": "border-box",
    "border-radius": '4px',
    fontWeight: 700,
    color: '#0C90B2',
    height: 55,
    fontSize: 16,
    width: 150,
    marginBottom: 15,
    backgroundColor: 'white',
    fontSize: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: "none",
    cursor: 'pointer',
  },
  paddleText: {
    color: "#000000",
    textAlign: "center",
    fontWeight: "700"
  },
  offerBadge:
    { position: 'absolute', top: -10, fontSize: 12, borderRadius: 4, width: 80, height: 24, color: 'white', backgroundColor: '#0C90B2', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }

};

export default Login;

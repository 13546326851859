
import React, { useState, useEffect } from "react";

import { IonButton, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol } from '@ionic/react';

import { Redirect } from "react-router-dom";

// import { useRecoilState } from 'recoil'
// import { userAtom } from "../atom.js"
// import { getLocalStorage } from './localstorage.js'
// import "../App.css";
import { Mixpanel } from "../pages/mixpanel";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { api_url } from "../App"
import './Menu.css';




export const Covid = () => {
    const [token, setToken] = useState(localStorage.getItem("token"))
    const [dataFetched, setDataFetched] = useState(false)
    const [covidState, setCovidState] = useState({
        wfh: false,
        self_employed: false,
        employed:false,
        otherSupport: false,
        otherFurlough: false,
        covidLoan: false,
        jsa: false,
        universalCredit: false,
        incomeESA: false,
        contributionESA: false,
        extra: false,
        seiss: false,
        laGrant: false, 
        furlough: false,
        oweHMRC: false,
        extraCosts: false
    })
    const [recoilFetched, setRecoilFetched] = useState(false)
    const [redirectPension, setRedirectPension] = useState(false)
    const [redirectHW, setRedirectHW] = useState(false)
    const [redirectEmploymentExpenses, setRedirectEmploymentExpenses] = useState(false)
    const [formsBenefits, setFormsBenefits] = useState([])
    const [formsEmployment, setFormsEmployment] = useState([])
    const [formsHW, setFormsHW] = useState([])
    const [formsFetched, setFormsFetched] = useState(false)
    const [edata, setEData] = useState({})
    const [origEData, setOrigEData] = useState([{}])
    const [pbdata, setPBData] = useState({})
    const [hwdata, setHWData] = useState({})
    const [taxYearId, setTaxYearId] = useState(0)
    const [alert, setAlert] = useState({
        open: false,
        alertTitle: "",
        alertMessage: ""
    })
    const [fromWebView, setFromWebView] = useState(false)

    const styles = {
        closed:{
            display: "none"
        },
        mainDiv:{
            padding: "20px"
        },
        webViewmainDiv:{
            margin: "10px"
        },

        checkboxStyle:{
            minWidth: "18px",
            fontSize: '32px',
            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
            justifyContent: 'flex-end',
            marginRight: "10px"
        },
        checkboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px',
             },
        webViewcheckboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px',
            marginRight: '50px'  
            },
        innerCheckboxContainer:{
            paddingLeft: "50px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        webViewInnerCheckboxContainer:{
            paddingLeft: "50px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        innerSecondaryCheckboxContainer:{
            paddingLeft: "100px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        webViewInnerSecondaryCheckboxContainer:{
            paddingLeft: "100px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        infoStyle:{
            backgroundColor: "rgba(226, 226, 226, 0.3)",
            borderRadius: "5px",
            minHeight: "fit-content",
            width: "100%",
            marginRight: "10px",
            marginTop: "10px",
            // marginLeft: "100px",
        },
        webViewInfoStyle:{
            backgroundColor: "rgba(226, 226, 226, 0.3)",
            borderRadius: "5px",
            minHeight: "fit-content",
           
        },
        innerStyle:{
            display: "flex"
        },
        webViewInnerStyle:{
            display: "flex",
            width: "90%"
        },
        paddingButton: {
            padding: "10px 10px 5px 10px"
        },
        paddingNoButton: {
            padding: "10px 10px 10px 10px"
        }


    }

    useEffect ( () => {
        Mixpanel.track("covid_support_page_loaded");
        window.addEventListener("message", function(data){
            console.log('message',data)
            if (typeof(data.data) == "string"){
                console.log('message string', data.data)
                if (data.data.includes('token:')) {
                    // window.alert(data.data.slice(6))
                    setToken(data.data.slice(6))
                    setFromWebView(true)
                    setRecoilFetched(true)
                }
            }
        }); 
        document.addEventListener("message", function(data){
            console.log('message',data)
            if (typeof(data.data) == "string"){
                console.log('message string', data.data)
                if (data.data.includes('token:')) {
                    // window.alert(data.data.slice(6))
                    setToken(data.data.slice(6))
                    setFromWebView(true)
                    setRecoilFetched(true)
                }
            }
        }); 

        if(!token){
            if (localStorage.getItem("token")){
                setToken(localStorage.getItem("token"))
                setRecoilFetched(true)
            }
            else{
                setRecoilFetched(false)
            }
        }
          else {
            setRecoilFetched(true)
          }
    }, [])

    useEffect(()=>{
        if (recoilFetched){
            getTaxYearId()

        }
    }, [recoilFetched])

    useEffect(() => {

        if (taxYearId!==0) {
            getFormData("pensions_benefits")
            getFormData("employment")
            getFormData("home_working")            
            getData()
            getForms()
        }
    }, [taxYearId])


    const getTaxYearId = () => {
        fetch(`https://${api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: token }
        })
        .then(res => res.json())
        .then(json => { 

            const years = json.years;
            const year_id = years.find(e => {return e.tax_year === '2020-2021' }).id
            setTaxYearId(year_id)

        });
    }


    useEffect(()=> {
        if(JSON.stringify(formsHW) !== "[]"){
            setFormsFetched(true)
        }

    }, [formsHW])

    useEffect(()=> {
        if(formsFetched){
            saveData()
        }

    }, [covidState])

    const getTimestamp = () => {
        return Math.floor(Date.now() / 1000)
    }
    
    const getData = () => {
        console.log("fetching previous data")
        console.log(token)
        console.log(getTimestamp())
        fetch(`https://${api_url}/getObjectData.php?type=covid1&ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: token }
        })
        .then(res => res.json())
        .then(json => {
            if (JSON.stringify(json.data)!== "[]"){
                setCovidState(json.data)
            }
            setDataFetched(true)
        })
    }
    
    const saveData = () =>{
        console.log("saving")
        const body = JSON.stringify({ 
            type: "covid1",
            data: {...covidState}
        });
        
        fetch(`https://${api_url}/saveObjectData.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: body,
            headers: { token: token }
        })
        .then(res => res.json())
        .then(json => {
            Mixpanel.track("covid_support_save_state");

            console.log(json)
        })
        
        
    }
    //   forms information
        const getForms = () => {
    
            fetch(`https://${api_url}/getTaxForms.php?ts=${getTimestamp()}`, {
                method: "GET",
                headers: { token: token }
            })
                .then(res => res.json())
                .then(json => {

                    if (json.error === 'unauthorized') {
                        window.location = '/logout'
                    }

                    const PBForm = json.forms.find(e => { return e.code==="SA100"})
                    const EForm = json.forms.find(e => { return e.code==="SA102"})
                    const HWForm = json.forms.find(e => { return e.code==="HW1"})

                    setFormsBenefits(PBForm)
                    setFormsEmployment(EForm)
                    setFormsHW(HWForm)
                });
        };

        const renderRow = (data, element) => {
            console.log(data)
            console.log(element)
            let outputRow = true;
            let format = (element.format === undefined) ? "text" : element.format

            function helpi(element) {
                if (element.help_link) {
                    return (
                        <a href={element.help_link} target="_new">
                            Info
                        </a>
                    )
                } else {
                    return "";
                }
            }
    
            return (
    
    
                <>
                    <IonRow style={{ display: "flex", flexDirection: element.multiline ? 'column' : 'row', width: "100%", paddingTop: '10px', marginBottom: '10px', borderBottomColor: 'grey', borderBottomWidth: '0px', borderBottomStyle: 'solid', marginRight: '50px' }}>
    
                        {element.heading ? (
                            <IonCol style={{ width: "70%", textAlign: 'left' }} key={element.name}>
                                <h3>{element.heading}</h3>
                                {element.title}</IonCol>
                        ) : (
                                <IonCol style={{ width: element.multiline ? "100%" : "70%", textAlign: 'left' }} key={element.name}>{element.title} {helpi(element)}</IonCol>
                            )}
    
                        <IonCol 
                            style = {{
                                textAlign : "right"}}
                        // style={{
                        //     width: element.multiline ? "100%" : "30%", alignItems: 'flex-end', verticalAlign: 'bottom', display: 'flex',
                        //     // justifyContent: 'flex-end',
                        //     // flexDirection: 'column',
                        //     // minWidth: '250px'
                        // }}
                        >
                               
                            {format == 'checkbox'
                                && (
                                        <IonCheckbox
                                        
                                            style={{
                                                minWidth: "10px",
                                                fontSize: '32px',
                                                "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0"
                                            }}
                                            checked={true}
                                        />
    
                                    
                                )}
    
                            {format == "select"
                                && (
                                    <Select
                                        value={getValue(data, element.name, element.default)}
                                        onChange={(e) => setValue(data, element.name, e.target.value, "select", element.length)}
                                    >
                                        {element.choices.map(el => {
                                            return (
                                                <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                )}
    
                            {/* anything else (not select or checkbox) */}
                            {format == "number" && (<>
                                <span style = {{lineHeight: element.multiline ? '100px' : '30px'}}>£ </span><TextField
                                        id={element.name}
                                        //maxLength={element.format === 'date' ? null : element.length}
                                        //mask={element.format === 'number' ? numberMask : element.format === 'date' ? dateMask() : stringMask(element.length)}
                                        
                                        style={{
                                            width: element.multiline ? "40%" : '40%',
                                            minWidth: "140px",
                                            textAlign: "right",
                                            borderRadius: 0,
                                            borderColor: "#14A7CC",
                                            borderStyle: "solid",
                                            borderBottomWidth: 2,
                                            backgroundColor: "#ffffff",
                                            fontSize: '16px',
                                            height: element.multiline ? '100px' : '30px'
                                        }}
                                        type = "number"
                                        multiline={element.multiline ? true : false}
                                        value={getValue(data, element.name)}
                                        onChange={(e) => setValue(data, element.name, e.target.value, element.format, element.length)}
                                        // placeholderChar="_"
                                        placeholder={element.multiline ? "Add further info here, e.g. details of computations or attachments provided" : ""}
                                        inputProps={{ 
                                            disableUnderline: true,
                                            style:{textAlign: "right", paddingRight: "10px"}
                                             }} 
                                        InputProps={{ disableUnderline: true}}
                                             />
                                             </>
                            )}
                            {format != "select" && format != "checkbox" && format !== "number"
                                && (
                                    <TextField
                                        id={element.name}
                                        //maxLength={element.format === 'date' ? null : element.length}
                                        //mask={element.format === 'number' ? numberMask : element.format === 'date' ? dateMask() : stringMask(element.length)}
                                        style={{
                                            width: element.multiline ? "40%" : '40%',
                                            minWidth: "140px",
                                            alignContents : "right",
                                            borderRadius: 0,
                                            borderColor: "#14A7CC",
                                            borderStyle: "solid",
                                            borderBottomWidth: 2,
                                            backgroundColor: "#ffffff",
                                            fontSize: '16px',
                                            height: element.multiline ? '100px' : '30px'
                                        }}
                                        multiline={element.multiline ? true : false}
                                        value={getValue(data, element.name)}
                                        onChange={(e) => setValue(data, element.name, e.target.value, element.format, element.length)}
                                        // placeholderChar="_"
                                        placeholder={element.multiline ? "Add further info here, e.g. details of computations or attachments provided" : ""}
                                        inputProps={{ style:{textAlign: "right", paddingRight: "10px"} }} 
                                        InputProps={{ disableUnderline: true}}
                                            />
                                )}
                                            
                        </IonCol>
    
    
                    </IonRow>
    
                    <IonRow style={{ display: "flex", flexDirection: 'row', width: "100%", paddingTop: '0px', marginBottom: '0px', borderBottomColor: 'grey', borderTopWidth: '1px', borderTopStyle: 'solid', margingRight: '50px' }} />
                </>
    
            )
        }
        const getFormData = (form) => {
            fetch(`https://${api_url}/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${taxYearId}&type=${form}`, {
                method: "GET",
                headers: { token: token }
            })
                .then(res => res.json())
                .then(json => {
                    

                    if (json.data) {
                        const json_data = JSON.parse(json.data);
                        if (form === 'employment') {
                            
                            setOrigEData(json_data)
                            setEData(json_data[0])
                        } else if (form == "home_working") {
                            
                            setHWData(json_data)
                        }
                        else{
                            
                            setPBData(json_data)
                        }
                    } 

                })
    
        }

        const getValue = (data, name, def = "") => {
            if (data.length === 0) {
                return ""
            }
            if (data[name]) {
                return data[name];
            } else {
                return def
            }
        }
    
        const setValue = (data, name, newValue, format, length) => {
            let retValue = newValue
            let curValue = data[name]
            let validData
            if (retValue != null) {
                if (format.toLowerCase() == "string") {
                    retValue = retValue.substr(0, length);
                }
    
                // if (format.toLowerCase() == "number") {
                //     if (newValue != "") {
                //         let matches = retValue.match(/\d+/g)
                //         if (matches == null) {
                //             matches = 0
                //         }
                //         if (matches.length > 0) {
                //             retValue = matches.join()
                //         }
                //         if (length != null) {
                //             retValue = retValue.substr(0, length);
                //         }
                //     }
                // }
    
                if (format.toLowerCase() == "date") {
                    retValue = "";
                    let cChar = ""
                    const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                    for (let i = 0; i < newValue.length; i++) {
                        cChar = newValue.charAt(i).match(dateMask[i]);
                        if (cChar != null) {
                            retValue = retValue + cChar;
                        }
                    }
    
                    
    
                if (curValue === undefined) { curValue = "" }
    
                if ((curValue.length === 1 && retValue.length === 2) || (curValue.length === 4 && retValue.length === 5)) { retValue = retValue + '/' }

                if (data == hwdata){
                    if (retValue.length === 10) {
                        let aDate = moment(retValue, 'DD/MM/YYYY', true);
                        let isValid = aDate.isValid();
                        if (!isValid) {
                            setTimeout(() => {
                                validData = {...data};
                                validData[name] = ""
                                setAlert({
                                    open: true,
                                    alertTitle: "Invalid date",
                                    alertMessage: "Please enter a valid date."
                                })
                                setHWData(validData)
                            }, 500)
                        }
                    }
                }
                else if (data == edata) {
                    if (retValue.length === 10) {
                        let aDate = moment(retValue, 'DD/MM/YYYY', true);
                        let isValid = aDate.isValid();
                        if (!isValid) {
                            setTimeout(() => {
                                validData = {...data};
                                validData[name] = ""
                                setAlert({
                                    open: true,
                                    alertTitle: "Invalid date",
                                    alertMessage: "Please enter a valid date."
                                })
                                setEData(validData)
                            }, 500)
                        }
                    }
                }
                else {
                    if (retValue.length === 10) {
                        let aDate = moment(retValue, 'DD/MM/YYYY', true);
                        let isValid = aDate.isValid();
                        if (!isValid) {
                            setTimeout(() => {
                                validData = {...data};
                                validData[name] = ""
                                setAlert({
                                    open: true,
                                    alertTitle: "Invalid date",
                                    alertMessage: "Please enter a valid date."
                                })
                            }, 500)
                        }
                    }
                    validData = {...data};
                    validData[name] = retValue;
                    setPBData([...validData])
                }
                }
                if (data == hwdata){
                    validData = {...data}
                    validData[name] = retValue;
                    setHWData(validData)
                }
                else if (data == edata) {
                    validData = {...data};
                    validData[name] = retValue;
                    setEData(validData)
                }
                else {
                    validData = {...data};
                    validData[name] = retValue;
                    setPBData(validData)
                }
            }
            
        }

        const saveFormData = (data, isEmp=false) => {
            let tax_column 
        
            if (data == hwdata){
               tax_column = "home_working"
            }
            else if (data == edata) {
                tax_column = "employment"
            }
            else {
                tax_column = "pensions_benefits"
            }
            // data["include"] = true
            // let dataPackage = JSON.stringify(data)

            var dataPackage
            if (isEmp) {
                //data['include'] = true;
                var emp_profile = origEData
                emp_profile[0] = data;                
                dataPackage = JSON.stringify(emp_profile);
            } else {
                data['include'] = true;
                dataPackage = JSON.stringify(data);
               
            }


            setAlert({
                open: true,
                alertTitle: "Saving",
                alertMessage: ""
            })

            fetch(`https://${api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${taxYearId}&type=${tax_column}`, {
                    method: "POST",
                    headers: { token: token },
                    body: dataPackage
                })
                    .then(res => res.json())
                    .then(json => {
                        if (json.status == "success") {
                            console.log("data saved")
                            Mixpanel.track("covid_support_save_form",{type:tax_column});

                            setTimeout( setAlert({ ...alert, open: false }), 7000)
                            
                         
                        } else {
                            setAlert({
                                open: true,
                                alertTitle: "Error",
                                alertMessage: "There was a problem saving the form."
                            })
                            
                        }

                    });
        }

        
        // end of forms information

    const wfhTrue = () => {
        if (covidState.wfh) {
            return(
                <div>
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer }>
                       
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.self_employed}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,
                                    self_employed: e.detail.checked
                                    })
                            }}
                        />
                        <div>I was self-employed</div>
                    </div>
                            {selfEmployedTrue()}
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.employed}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    employed: e.detail.checked
                                    })
                            }}
                        />
                        <div>I was employed</div>
                    </div>
                    {employedTrue()}

                </div>
            )
        }
    }

    const mapHWforms = () => {
        let forms = JSON.parse(formsHW.form_json).fields
        let mappingForms = [forms[1], forms[2]]
        return(<IonGrid style = {styles.paddingNoButton}>
  
            {mappingForms.map(element => {
                return renderRow(hwdata, element)
            })}
            <IonRow>
                <IonCol>
                <IonButton
                class = "ion-button-money"
                style ={{marginTop: "10px"}}
                onClick = {()=>{
                    saveData()
                    saveFormData(hwdata)
                }}>
                    Submit Data
                </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    const selfEmployedTrue = () => {
        if(covidState.self_employed){
        return(
            <div style = {styles.infoStyle}>
                <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingButton}>
                        {/* You can claim for any extra costs incurred while working from home.
                        Make sure you fill HW1 - Home working on the forms section, and we'll work out the calculations for you. */}
                        You can claim for any extra costs incurred while working from home.
                        To calculate this value we need to know:
                    </div>
                </div>
                {formsFetched ? mapHWforms() : <div/>}
                {/* <IonButton
                class = "ion-button-money"
                style ={{paddingBottom: "10px"}}
                onClick = {()=>{
                    saveData()
                    setRedirectHW(true)
                }}>
                    Fill it Now
                </IonButton> */}
            </div>
        )
        }
    }

    const employedTrue = () => {
        if (covidState.employed){
            return(
                <div>
                      <div style={ fromWebView ? styles.webViewInnerSecondaryCheckboxContainer : styles.innerSecondaryCheckboxContainer }>
        
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={covidState.extraCosts}
                    onIonChange={(e) => {
                        setCovidState({
                            ...covidState,   
                            extraCosts: e.detail.checked
                                })
                            }}
                        />
                        <div>
                            I incurred extra costs (including heating, phone, internet or other utilities) from working from home, that weren't fully reimbursed by my employer. <strong>This will save you tax.</strong>
                        </div>
        
                     </div>
                     {extraCostsTrue()}
 
                   <div style = {styles.infoStyle}>
                        <div style = {styles.innerStyle}>
                            <div class = "info_circle"/>
                            <div style = {styles.paddingButton}>
                            Enter the figures from your P60 by selecting Employment from the menu. You'll get that from your employer by May 2021.                           {" "}
                            {fromWebView ? <a style = {{
                               
                                textDecorationLine: 'underline', 
                                cursor:'pointer',
                                color: "blue"
                                }} onClick = {()=>{
                                    window.ReactNativeWebView.postMessage('go_income')
                                    Mixpanel.track("covid_support_link_to_employment");
                                    }}>Go to income settings</a> :
                                <a href = "/page/Employment" onClick = {()=>{ Mixpanel.track("covid_support_link_to_employment");}}>Go to income settings</a>}
                            </div>
                        </div>
                    </div>

           
            </div>
            )
        }
    }

    const mapEforms = () => {
        let forms = JSON.parse(formsEmployment.form_json).fields
        const mappingForms = [ forms.find(e => { return e.name === "box_20"}) ]
        return(<div style = {styles.paddingNoButton}>
  
            {mappingForms.map(element => {
                return renderRow(edata, element)
            })}
            <IonButton
                class = "ion-button-money"
                style ={{marginTop: "10px"}}
                onClick = {()=>{
                    saveData()
                    saveFormData(edata, true)
                }}>
                    Submit Data
                </IonButton>
            </div>
        )
    }

    const extraCostsTrue = () => {
        if (covidState.extraCosts){
        return(
            <div style = {styles.infoStyle}>
                <div style = {styles.innerStyle}>
                    <div class = "check_circle"/>
                    <div style = {styles.paddingNoButton}>  
                        You can claim this on your tax return. The default you can claim is £312 a year. To claim this, enter £312 in the box and click on Submit Data (or add that amount to other claims in this box).    
                        </div>
                    </div> 
                    {formsFetched ? mapEforms() : <div/>}
 
                    {/* <IonButton
                        class = "ion-button-money"
                        style ={{paddingBottom: "10px"}}
                        onClick = {()=>{
                            saveData()
                            setRedirectEmploymentExpenses(true)
                        }}>
                        Fill it Now
                    </IonButton> */}
            </div>
        )
        }
    }

    const seissTrue = () => {
        if (covidState.seiss){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingNoButton}>
                    This is taxable income, and it should be added as <strong>"SEISS grant income".</strong>
                    </div>
                </div>  
                </div>
            )
        }
    }

    const otherSupportTrue = () => {
        if (covidState.otherSupport){
            return(
                <div>
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.covidLoan}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    covidLoan: e.detail.checked
                                    })
                            }}
                        />
                        <div>COVID loans</div>
                    </div>
                    {covidLoanTrue()}

                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.jsa}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    jsa: e.detail.checked
                                    })
                            }}
                        />
                        <div>Jobseeker's Allowance (JSA)</div>
                    </div>
                    {jsaTrue()}
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.universalCredit}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    universalCredit: e.detail.checked
                                    })
                            }}
                        />
                        <div>Universal Credit (UC)</div>
                    </div>
                    {universalCreditTrue()}
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.incomeESA}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    incomeESA: e.detail.checked
                                    })
                            }}
                        />
                        <div>Income related Employment and Support Allowance (ESA)</div>
                    </div>
                    {incomeEsaTrue()}
                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.contributionESA}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    contributionESA: e.detail.checked
                                    })
                            }}
                        />
                        <div>Contribution-based Employment and Support Allowance (ESA)</div>
                    </div>
                    {contributionEsaTrue()}

                    <div style={ fromWebView ? styles.webViewInnerCheckboxContainer : styles.innerCheckboxContainer  }>
                       
                       <IonCheckbox
                           style={styles.checkboxStyle}
                           checked={covidState.otherFurlough}
                           onIonChange={(e) => {
                               setCovidState({
                                   ...covidState,
                                   otherFurlough: e.detail.checked
                                   })
                           }}
                       />
                        <div>Furlough payments for my employee(s)</div>
                   </div>
                   {otherFurloughTrue()}
                </div>
            )
        }
    }

    const laGrantTrue = () => {
        if (covidState.laGrant){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingNoButton}>
                    If this relates to your business, add this income as <strong>"SEISS grant income"</strong>.
                    </div>
                </div>  
                </div>
            )
        }

    }

    const otherFurloughTrue = () => {
        if (covidState.otherFurlough){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingNoButton}>
                    Coronavirus Job Retention Scheme (CJRS/furlough) support to help me pay my employee(s)
                    If you received support to help you furlough your staff, add it as <strong>"SEISS grant income"</strong>.
                    </div>
                </div>  
                </div>
            )
        }
    }

    const covidLoanTrue = () => {
        if (covidState.covidLoan){
            return(
                <div style = {styles.infoStyle}>
                <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingNoButton}>
                    These do not get included on your tax return. Any interest will be treated as an expense when you pay it. Do not add COVID loans in untied.                    </div>
                </div>  
                </div>
            )
        }
    }

    const mapJSAforms = () => {
        let forms = JSON.parse(formsBenefits.form_json).fields
        const mappingForms = [ forms.find(e => { return e.name === "tr_3_box_15"}) ]
        return(
        <div style = {styles.paddingNoButton}>
  
            {mappingForms.map(element => {
                return renderRow(pbdata, element)
            })}
            <IonButton
                class = "ion-button-money"
                style ={{marginTop: "10px"}}
                onClick = {()=>{
                    saveData()
                    saveFormData(pbdata)
                }}>
                    Submit Data
                </IonButton>
            </div>
        )
    }

    const jsaTrue = () => {
        if (covidState.jsa){
            return(
                <div style = {styles.infoStyle}>
                <div style = {styles.innerStyle}>
                    <div class = 'info_circle'/>
                    <div style = {styles.paddingButton}>
                    {/* If you were on Jobseeker's Allowance at the end of the year, you'll get a form P60U or P45U. Enter the details on Box 15 - SA100 - Pension and Benefits.
                    If you have an HMRC login you can connect to HMRC to link that information to untied <strong>See how to do that here</strong>. */}
                    If you were on Jobseeker's Allowance you'll get a form (P45U or P60U). There you can find the information you need. This will be included in your tax calculation as State Pensions and Benefits.                    </div>
                </div>  

                {formsFetched ? mapJSAforms() : <div/>}
                {/* <IonButton
                class = "ion-button-money"
                style ={{paddingBottom: "10px"}}
                onClick = {()=>{
                    saveData()
                    setRedirectPension(true)
                }}>
                    Fill it Now
                </IonButton> */}
                </div>
            )
        }
    }

    const universalCreditTrue = () => {
        if(covidState.universalCredit){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>
                    <div class = "info_circle"/>
                    <div style = {styles.paddingNoButton}>
                    You do not need to add UC payments.
                    </div>
                </div>
                </div>
            )
        }
    }
    const incomeEsaTrue = () => {
        if(covidState.incomeESA){
            return(
                <div style = {styles.infoStyle}>
                    <div style = {styles.innerStyle}>
                        <div class = "info_circle"/>
                        <div style = {styles.paddingNoButton}>
                        You do not need to add income related ESA. 
                        </div>
                    </div>  
                </div>
            )
        }
    }

    const mapESAforms = () => {
        let forms = JSON.parse(formsBenefits.form_json).fields
        const mappingForms = [ forms.find(e => { return e.name === "tr_3_box_14"}) ]
        return(
        <div style = {styles.paddingNoButton}>
  
            {mappingForms.map(element => {
                return renderRow(pbdata, element)
            })}
            <IonButton
                class = "ion-button-money"
                style ={{marginTop: "10px"}}
                onClick = {()=>{
                    saveData()
                    saveFormData(pbdata)
                }}>
                    Submit Data
                </IonButton>
            </div>
        )
    }

    const contributionEsaTrue = () => {
        if(covidState.contributionESA){
            return(
                <div style = {styles.infoStyle}>
                     <div style = {styles.innerStyle}>
                        <div class = "info_circle"/>
                        <div style = {styles.paddingButton}>
                            If you had contribution based ESA you'll get a form (P45U or P60U). There you can find the information you need here.
                        </div>
                        </div> 
                        {formsFetched ? mapESAforms() : <div/>}
                </div>
            )
        }
    }
    const extraTrue = () => {
        if(covidState.extra){
            return(
                <div style = {styles.infoStyle}>
                    <div style = {styles.innerStyle}>
                        <div class = "info_circle"/>
                        <div style = {styles.paddingNoButton}>
                        In general, all grants will be taxed as business income. If in doubt, <a href="https://help.untied.io/" target ="_new">contact us</a> and we can help you work out how it should be treated
                        </div>
                    </div>  
                </div>
            )
        }
    }

    
    const furloughTrue = () => {
        if (covidState.furlough){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>
                    <div class = "check_circle"/>
                    <div style = {styles.paddingNoButton}>  
                    Your furlough payment will be included in your salary. There is no need to declare it separately.
                    </div>
                </div>  
                </div>
            )
        }
    }

    const oweHMRCTrue = () => {
        if (covidState.oweHMRC){
            return(
                <div style = {styles.infoStyle}>
                     <div style = {styles.innerStyle}>
                        <div class = "info_circle"/>
                        <div style = {styles.paddingNoButton}>
                            If you have not already agreed a payment plan with HMRC, we suggest that you contact them. See untied's help on this <strong><a href="https://help.untied.io/article/43-what-other-help-is-out-there" target= "_blank">here.</a></strong>
                        </div>
                    </div>  
                </div>
            )
        }
    }


    const displayWFH = () => {
        return(
            <div  >
                <div style={ fromWebView? styles.webViewcheckboxContainer : styles.checkboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={covidState.wfh}
                    onIonChange={(e) => {
                        setCovidState({
                            ...covidState,
                            wfh: e.detail.checked,
                        })
                    }}
                />
                 <div>I worked from home during 2020/21</div>
                 </div>
                {wfhTrue()}
            </div>
        )
    }

    const displaySeiss = () => {
        return( <div>
        <div style={ fromWebView? styles.webViewcheckboxContainer : styles.checkboxContainer } >
            
            <IonCheckbox
                style={styles.checkboxStyle}
                checked={covidState.seiss}
                onIonChange={(e) => {
                    setCovidState({
                        ...covidState,
                        seiss: e.detail.checked
                    })
                }}
            />
            <div>I received a SEISS (Self-Employment Income Support Scheme)</div>

            </div>
            {seissTrue()}
            </div>
            )
    }

    const displayLocalAuthority = () => {
        return( <div>
            <div style={ fromWebView? styles.webViewcheckboxContainer : styles.checkboxContainer } >
                
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={covidState.laGrant}
                    onIonChange={(e) => {
                        setCovidState({
                            ...covidState,
                            laGrant: e.detail.checked
                        })
                    }}
                />
                <div>I received a local authority COVID grant</div>
                </div>
                {laGrantTrue()}
                </div>
                )

    }

    const otherCovidSupport = () => {
        return(
            <div >
                <div style={ fromWebView? styles.webViewcheckboxContainer : styles.checkboxContainer }>
                    <IonCheckbox
                        style={styles.checkboxStyle}
                        checked={covidState.otherSupport}
                        onIonChange={(e) => {
                            setCovidState({
                                ...covidState,
                                otherSupport: e.detail.checked,
                            })
                        }}
                    />
                    <div>I received COVID loans or other support</div>
                 </div>
                {otherSupportTrue()}
            </div>
        )
    }

    const displayFurlough = () => {
        return( <div>
            <div style={ fromWebView? styles.webViewcheckboxContainer : styles.checkboxContainer } >
                
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={covidState.furlough}
                    onIonChange={(e) => {
                        setCovidState({
                            ...covidState,
                            furlough: e.detail.checked
                        })
                    }}
                />
                <div>I was furloughed by my employer during this period</div>
                </div>
                {furloughTrue()}
                </div>
                )
    }

    const extra = () => {
        return(<div>
                <div style={ styles.checkboxContainer }>
                        
                        <IonCheckbox
                            style={styles.checkboxStyle}
                            checked={covidState.extra}
                            onIonChange={(e) => {
                                setCovidState({
                                    ...covidState,   
                                    extra: e.detail.checked
                                    })
                                    saveData()
                            }}
                        />
                        <div>This form missed other COVID support I received</div>
                    </div>
                    {extraTrue()}
        </div>)
    }


    const displayOweHMRC = () => {
        return( <div>
            <div style={ fromWebView ? styles.webViewcheckboxContainer : styles.checkboxContainer } >
                
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={covidState.oweHMRC}
                    onIonChange={(e) => {
                        setCovidState({
                            ...covidState,
                            oweHMRC: e.detail.checked
                        })
                    }}
                />
                <div>I still owe HMRC money from last year</div>
                </div>
                {oweHMRCTrue()}
                </div>
                )
    }

    const displayMain = () => {

        if(dataFetched){
        // if(true){
            if (redirectPension){
                return(
                        <Redirect
                    to={{
                    pathname: "/taxForms",
                    state: { year: "2020-21", form: "SA100" }
                    }}
                    />
                )
            }
            else if (redirectHW){
                return(
                <Redirect
                to={{
                pathname: "/taxForms",
                state: { year: "2020-21", form: "HW1" }
                }} />
                )
            }
            else if (redirectEmploymentExpenses){
                return(
                    <Redirect
                    to={{
                    pathname: "/taxForms",
                    state: { year: "2020-21", form: "SA102" }
                    }} />
                    )
            }
            else {
            return(
            <div style = {fromWebView ? styles.webViewmainDiv : styles.mainDiv}>
                <IonAlert
                    isOpen={alert.open}
                    onDidDismiss={() => {
                        setAlert({ ...alert, open: false })
                    }}
                    header={alert.alertTitle}
                    message={alert.alertMessage}
                    buttons={['OK']}
                />
                <div>This page helps you make sure your COVID support is properly included in your tax return. <strong> Choose your answers for the 2020/21 tax year which ran from 6 April 2020 - 5 April 2021. You will need to save each section separately as you enter information.</strong></div>
                <div>Select all options that apply:</div>
                {displayWFH()}
                {displaySeiss()}
                {displayLocalAuthority()}
                {displayFurlough()}
                {otherCovidSupport()}
                {displayOweHMRC()}
                {extra()}
                {/* <IonButton  
                    className="ion-button-money"
                    onClick = {()=>{
                        saveData()
                    }}>
                    Save 
                </IonButton> */}
            </div>)
            }
        }
    }

    return (
        displayMain()
    )
}


export default Covid
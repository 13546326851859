import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.min.css";


// function isIE() {

//     var ua = window.navigator.userAgent;
//     var msie = ua.indexOf("MSIE ");

//     if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
//     {
//         return true;
//     }

//     return false;
// }

// if (isIE()) {
//     window.alert('Sorry, this app does not work in Internet Explorer - please install Chrome from google.com/chrome');
//     window.location.href = "https://google.com/chrome";
// }

// if (/MSIE 10/i.test(navigator.userAgent)) {
//     // This is internet explorer 10
//     window.alert('Sorry, this app does not work in Internet Explorer - please install Chrome from google.com/chrome');
//     window.location.href = "https://google.com/chrome";
// }

// if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
//     // This is internet explorer 9 or 11
//     window.alert('Sorry, this app does not work in Internet Explorer - please install Chrome from google.com/chrome');
//     window.location.href = "https://google.com/chrome";
// }

// if (/Edge\/\d./i.test(navigator.userAgent)) {
//     // This is Microsoft Edge
//     window.alert('Sorry, this app does not work in Edge - please install Chrome from google.com/chrome');
//     window.location.href = "https://google.com/chrome";
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from "react";
import Collapsible from 'react-collapsible';
import { NavBar, PageHeader, CustomButton } from "../components/Common"
import './Page.scss';


const Home = () => {
    const [collapsible, setCollapsible] = useState({ itemTop: false, itemMiddle: false, itemBottom: false });

    var urlParams = new URLSearchParams(window.location.search);
    const target = urlParams.get('target')
    if (target != null) {
        window.location.pathname = '/page/' + target;
        return <div />;
    }

    return (
        <IonPage className="selectable">
            <NavBar />
            <IonContent>
                <PageHeader label="Welcome to untied lite" />
                <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>
                    <IonRow>
                        <IonCol>
                            <p><span className="notranslate">untied</span> makes taxes quick, simple and easy.</p>

                            <h5>untied in 3 steps</h5>

                            <div className="man-red mt-2 mb-2" />
               
                            1. Enter your earnings and work-related expenses<br />
                            2. Fill in your profile<br/>
                            3. Submit your tax return directly to HMRC from untied lite.<br/>
                           
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-2">
                        <IonCol size-sm="3" />
                        <IonCol size-sm="6">
                            <CustomButton
                                linkTo="/page/EarningsExpenses"
                                label="Start"
                            />
                        </IonCol>
                        <IonCol size-sm="3" />
                    </IonRow>

                    <IonRow>
                        <IonCol>

                        <Collapsible
                                trigger={(
                                    () => (
                                        <div className="mt-3">
                                            <h5>Using untied lite
                                                <i className={!collapsible.itemBottom ? "collapsed" : "expanded"} />
                                            </h5>
                                        </div>
                                    )
                                )()}
                                containerElementProps={{ id: "itemBottom" }}
                                onOpen={() => {
                                    setCollapsible({
                                        itemTop: collapsible.itemTop,
                                        itemMiddle: collapsible.itemMiddle,
                                        itemBottom: true
                                    });
                                }}
                                onClose={() => {
                                    setCollapsible({
                                        itemTop: collapsible.itemTop,
                                        itemMiddle: collapsible.itemMiddle,
                                        itemBottom: false
                                    });
                                }}
                            >
                                <div>
                                    <ul>
                                    
                                    <li>untied lite was designed for self-employed people with flexible income from platforms like Deliveroo and Uber</li>

                                    <li>some people prefer untied Pro, which offers more features, like linking bank accounts and automatic tagging of income and expenses</li>

                                    <li>if you want your journeys logged automatically you need untied Pro. </li>

                                    <li>if you have other income sources (eg property income) you need untied Pro.</li>

                                    <li>contact us at <a href="mailto:upgrade@untied.io">upgrade@untied.io</a> if you want to try Pro after using lite</li>

                                </ul>
                                </div>
                            </Collapsible>

                            <p style={{marginTop:20}}>If you have any other questions contact us at <a href="mailto:help@untied.io">help@untied.io</a></p>

                            <div style={{width:'100%', textAlign:'center'}}>
                            <img
                                style={{maxWidth:'90%'}}
                                alt=""
                                src={require("../images/hmrc-recognised.png").default}
                            />
                            </div>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default Home;

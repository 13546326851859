import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from "react";
import Collapsible from 'react-collapsible';
import { NavBar, PageHeader, CustomButton } from "../components/Common"
import './Page.scss';
import { api_url } from "../App"


const Deserved = (props) => {

    return (
        <IonPage className="selectable">
            <NavBar />
            <IonContent>
                <PageHeader label="You deserve it" />
                <IonGrid className="ml-2 mr-2 m-auto mt-4 pt-4" fixed={true}>
                    <IonRow>
                        <IonCol style={{ textAlign: 'center' }}>

                            <iframe src="https://giphy.com/embed/rY93u9tQbybks" width="100%" height="100%" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>

                        </IonCol>
                    </IonRow>
                    <IonRow>

                        <IonCol style={{ textAlign: 'center' }}>
                            <p>Your taxes are done! You can close the page and go celebrate!</p>
                        </IonCol>

                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default Deserved;

import {
  IonContent,
  IonPage,
  IonTitle,
  IonItem,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonDatetime,
  IonLabel,
  IonLoading,
  IonText,
  IonPopover,
  IonTextarea
} from '@ionic/react';
import React, { Component } from "react";

import { informationCircleSharp } from 'ionicons/icons';
import { CustomButton, CustomButtonInverse, NavBar, PageHeader } from "../components/Common"
import { api_url } from "../App"
import { Mixpanel } from "./mixpanel";
import moment from 'moment';
import './Page.scss';
import { mainModule } from 'process';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      utr: "",
      showHelp: false,
      itemTop: false,
      showNINinfo: false,
      showUTRinfo: false
    };
  }

  componentDidMount() {
    Mixpanel.track("loaded_profile");
    this.getProfile();
  }

  getProfile() {
    let timeStamp = Math.floor(Date.now() / 1000);
    const token = localStorage.getItem("token");
    fetch(`https://${api_url}/getUserProfile.php?ts=${timeStamp}`, {
      method: "GET",
      headers: { token: token }
    })
      .then(res => res.json())
      .then(json => {

        if (json.error) {
          this.props.history.push("/logout");
          return;
        }

        var date
        if (json.dob == null || json.dob === "") {
          date = moment(new Date()).subtract(18, "years").format('YYYY-MM-DD')
        } else {
          date = json.dob
          if (date.includes('/')) {
            date = date.split("/").reverse().join("-");
          }
          date = moment(new Date(date)).format('YYYY-MM-DD')
        }

        //let date = new Date(json.dob)
        //date = moment(date).format('YYYY-MM-DD')
        this.setState({ ...json, dob: date, original_number: json.phone_verified === 'Y' ? json.phone : "" })
      })
  }

  parseDate(dateToCheck){
    let date = new Date(dateToCheck)
    date = moment(date).format('YYYY-MM-DD')
    return date
  }

  verifySMSCode() {
    const code = this.state.smsCode;

    const phone = this.state.phone;
    const token = localStorage.getItem("token");

    fetch(
      `https://${api_url}/twilio/sendVerify.php`,
      {
        method: "POST",
        body: JSON.stringify({ phone_number: phone, code: code }),
        headers: { token: token }
      }
    )
      .then(res => res.json())
      .then(json => {

        if (json.approved === false) {

          this.setState({
            showHelp: true, helpTitle: 'Invalid code', helpMainTitle: 'Error',
            helpText: "Sorry that code is not valid. Please try again or cancel and resend.",
            showLoading: false
          })


        } else {
          //all good, save ...
          this.setState({ showPhoneVerify: false, original_number: phone, helpMainTitle: 'Help' }, () => this.saveProfile());
        }
      })
  }

  sendSMSVerification() {

    const phone = this.state.phone;
    const token = localStorage.getItem("token");

    fetch(
      `https://${api_url}/twilio/sendVerify.php`,
      {
        method: "POST",
        body: JSON.stringify({ phone_number: phone }),
        headers: { token: token }
      }
    )
      .then(res => res.json())
      .then(json => {

        if (json.number === false) {
          //pop up error about number ...
          this.setState({ showPhoneVerify: false });

          this.setState({
            showHelp: true, helpTitle: 'Invalid phone number', helpMainTitle: 'Error',
            helpText: 'Please check your phone number is valid. If it is not a UK number please start the number with + followed by the country code. E.g. +34XXXXXXXXXX',
            showLoading: false
          })

        } else {
          this.setState({ showPhoneVerify: true, phone: json.number, phone_type: json.type, helpMainTitle: 'Help' })
        }
      })

  }

  renderPhoneVerifyModal() {

    const message = this.state.phone_type === 'mobile'
      ? "We have sent you a text message with a verification code. Please wait for the text and then enter the verification code below to confirm your phone number:"
      : "We are calling your number with a verification code. Please stand by for a phone call and then enter the verification code provided below to confirm your phone number:";

    return (

      <IonPopover
        className="my-ion-popover"
        isOpen={this.state.showPhoneVerify==true}
        onDidDismiss={e => this.setState({ showPhoneVerify: false })}
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle style={{ paddingLeft: 0 }}>We need to verify your number</IonTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonText>{message}</IonText>
            </IonCol>
          </IonRow>


          <IonRow>
            <IonCol className="text-center">
              <IonItem className="money-input">
                <IonInput value={this.state.smsCode}
                  onIonChange={e => this.setState({ smsCode: e.detail.value })}
                  placeholder="Code" inputmode="numeric" type="number" />
              </IonItem>
            </IonCol>
          </IonRow>


          <IonRow>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
              <CustomButtonInverse
                onClick={() => {
                  this.setState({ showPhoneVerify: false });
                }}
                label="Cancel"
              />
            </IonCol>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
              <CustomButton
                onClick={() => {
                  this.verifySMSCode();
                }}
                label="Verify"
              />
            </IonCol>
          </IonRow>

        </IonGrid>
      </IonPopover>

    )
  }

  saveProfile() {
    let timeStamp = Math.floor(Date.now() / 1000);
    const token = localStorage.getItem("token");

    if (this.state.name === "" || this.state.name == null) {
      this.setState({
        showHelp: true, helpTitle: 'Name missing',
        helpText: 'Please enter your name.',
        showLoading: false
      });
      return;
    }

    // if (!postcodeValidator(this.state.postcode, 'UK')) {
    //   this.setState({
    //     showHelp: true, helpTitle: 'Postcode error',
    //     helpText: 'Please check your postcode is correct.',
    //     showLoading: false
    //   })
    //   return;
    // }

    if (this.state.postcode === "" || this.state.postcode == null) {
      this.setState({
        showHelp: true, helpTitle: 'Postcode missing',
        helpText: 'Please enter your postcode.',
        showLoading: false
      });
      return;
    }

    if (this.state.phone != null) {
      const regex = /^(\+{0,1})(\d*)$/;
      if (!regex.test(this.state.phone.replace(/\s+/g, ''))) {
        this.setState({
          showHelp: true, helpTitle: 'Phone number invalid',
          helpText: 'Please check your phone number.',
          showLoading: false
        });
        return;
      }
    }

    let payload = {
      name: this.state.name,
      address: this.state.address,
      postcode: this.state.postcode,
      nin: this.state.nin,
      utr: this.state.utr ? this.state.utr.replace(" ", "") : "",
      dob: this.state.dob ? this.state.dob : "",
      // dob: this.state.dob ? moment(this.state.dob).format("DD/MM/YYYY") : "",
      phone: this.state.phone,
      phone_verified: this.state.phone === this.state.original_number ? "Y" : "N",
      moved: this.state.moved
    };

    fetch(`https://${api_url}/saveUserProfile.php?ts=${timeStamp}`, {
      method: "POST",
      headers: { token: token },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(json => {
        Mixpanel.track("saved_profile");
        this.setState({ /* showSaved: true, */ showLoading: false });
        this.getProfile();

        //has phone number changed .... (and not empty or null)
        if ((this.state.phone !== "") && (this.state.phone != null)) {
          if ((this.state.phone !== this.state.original_number)) {
            //this.setState({ showPhoneVerify: true, showLoading: false })

            //ask ...

            this.setState({ showLoading: false, showConfirmVerification: true });
            //return;
          } else {
            this.setState({ showSaved: true });
          }
        } else {
          this.setState({ showSaved: true });
        }

      })

  }

  render() {


    const verified_message =
      (this.state.phone === "") || (this.state.phone == null)
        ? ""
        : (this.state.phone === this.state.original_number) ? " (verified)" : " (unverified)";

    return (
      <IonPage className="selectable">
        <NavBar label="1. Profile" />

        <IonContent>
          <PageHeader label="Profile" />

          {this.renderPhoneVerifyModal()}

          <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
            <IonRow>
              <IonCol>
                <IonText class="text-muted">
                  <em>{this.state.email}</em>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Name:</IonLabel>
                  <IonInput value={this.state.name} data-tip="Enter your name here"
                    onIonChange={e => this.setState({ name: e.detail.value })}
                    placeholder="" inputmode="text" type="text" />
                </IonItem >
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Address:</IonLabel>
                  <IonTextarea value={this.state.address} data-tip="Enter your address here"
                    onIonChange={e => this.setState({ address: e.detail.value })}
                    placeholder="" inputmode="text" type="text" rows={3} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Postcode:</IonLabel>
                  <IonInput value={this.state.postcode} data-tip="Your postcode"
                    onIonChange={e => this.setState({ postcode: e.detail.value })}
                    placeholder="" inputmode="text" type="text" />
                </IonItem >
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Phone number{verified_message}:</IonLabel>
                  <IonInput value={this.state.phone}
                    onIonChange={e => {
                      // let verified = 'Y'
                      // if (this.state.phone != "" && this.state.original_number != "" && this.state.phone_verified === 'Y') {
                      //   console.log(this.state.phone, e.detail.value, this.state.original_number)
                      //   if (e.detail.value != this.state.original_number) {
                      //     verified = 'N';
                      //   }
                      // }
                      this.setState({ phone: e.detail.value })
                    }}
                    placeholder="Phone number" inputmode="tel" type="tel" />
                </IonItem >
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem 
                className="input-container"
                >

                  {/* <IonLabel position="floating">Date of birth:</IonLabel>
                  <IonDatetime displayFormat="DD MMM YYYY" placeholder="Select your date of birth" value={this.state.dob}
                    onIonChange={e => this.setState({ dob: e.detail.value })} /> */}

                  <IonLabel position =  "stacked" style ={{marginBottom: 15}} >Date of birth</IonLabel>
                                    <input 
                                    type= "date" 
                                    value = {this.state.dob}
                                    style = {{border: "none", width: "100%", height: "fit-content", font: "400 14px Arial", backgroundColor: "white", color: "black"}}
                                    onChange={e => {                      
                                        this.setState({ dob: e.target.value })                                    
                                    }} 
                                    />
                
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">National Insurance Number:</IonLabel>
                  <IonInput value={this.state.nin}
                    onIonChange={e => this.setState({ nin: e.detail.value })}
                    placeholder="" inputmode="text" type="text" />
                  <IonIcon color="primary" className="mr-0" slot="end" icon={informationCircleSharp}
                    onClick={() => {
                      this.setState({ showNINinfo: true })
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="input-container">
                  <IonLabel position="floating">Unique Taxpayer Reference:</IonLabel>
                  <IonInput value={this.state.utr}
                    onIonChange={e => this.setState({ utr: e.detail.value })}
                    placeholder=""  inputmode="numeric" type="number" maxLength={10} />
                  <IonIcon color="primary" className="mr-0" slot="end" icon={informationCircleSharp}
                    onClick={() => {
                      this.setState({ showUTRinfo: true })
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size-sm="3" />
              <IonCol size-sm="6">
                <CustomButton
                  onClick={() => {
                    this.setState({ showLoading: true });
                    this.saveProfile();
                  }}
                  label="Save profile"
                />
              </IonCol>
              <IonCol size-sm="3" />
            </IonRow>


            <IonRow>
              <IonCol>
                <p>If you don't have any of this information, you can find it in your online HMRC <a href="https://www.gov.uk/personal-tax-account" target="_new">personal tax account</a> or click <span className="strong-text">Help</span> in the menu for more info.</p>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* <ReactTooltip place="top" /> */}

          <IonAlert
            isOpen={this.state.showHelp}
            onDidDismiss={() => this.setState({ showHelp: false })}
            header={'Help'}
            subHeader={this.state.helpTitle}
            message={this.state.helpText}
            buttons={[
              {
                text: 'Translate',
                cssClass: 'text-center col-5',
                handler: blah => {
                  var text = document.body.innerText;
                  var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                  window.open(url, '_untiedTranslater');
                  //this.setState({ amlDetails: false })
                }
              },
              {
                text: 'OK',
                role: 'OK',
                cssClass: 'text-center col-5'
              }
            ]}
          />

          <IonAlert
            isOpen={this.state.showSaved}
            onDidDismiss={() => {
              this.setState({ showSaved: false });
            }}
            header={'Saved'}
            subHeader={''}
            message={'Your profile has been saved'}
            buttons={[{
              text: 'Go to tax filing',
              role: 'Go to tax filing',
              cssClass: 'text-center col-12',
              handler: () => {
                this.props.history.replace('/page/TaxFiling');
              }
            }]}
          />

          <IonAlert
            isOpen={this.state.showConfirmVerification}
            onDidDismiss={() => this.setState({ showConfirmVerification: false })}
            header={'Phone number verification'}
            //subHeader={this.state.helpTitle}
            message={"Your profile has been saved.<br><br>The phone number entered isn't verified yet.<br><br> Do you want to do this now?"}
            buttons={[
              {
                text: 'No, continue',
                role: 'cancel',
                cssClass: 'text-center col-5 alert-cancel-button',
                handler: () => {
                  this.props.history.replace('/page/TaxFiling');
                  this.setState({ goToEarnings: true })
                }

              },
              {
                text: 'Yes, verify',
                cssClass: 'text-center col-5',
                handler: blah => {
                  this.setState({ showConfirmVerification: false }, () =>
                    this.sendSMSVerification())
                }
              },
            ]}
          />

          <IonAlert 
            cssClass="utrAlert"
            isOpen={this.state.showUTRinfo}
            onDidDismiss={() => {
              this.setState({ showUTRinfo: false })
            }}
            header={"Unique Taxpayer Reference"}
            subHeader={""}
            message={'A UTR number is ten digits long, and HMRC uses it to identify you. You will get one when you register for self-employment. If you have an HMRC log in you can find this number in your account. ' +
              '<br /><br />' + 
              "Don't have a UTR yet? untied can help you, <a href='https://join.untied.io/' target='_new'>click here</a>"}
            buttons={[{
              text: 'OK',
              role: 'OK',
              cssClass: 'text-center col-12'
            }]}
          />

          <IonAlert
          cssClass="utrAlert"
            isOpen={this.state.showNINinfo}
            onDidDismiss={() => {
              this.setState({ showNINinfo: false })
            }}
            header={"National Insurance Number"}
            subHeader={""}
            message={'Your National Insurance Number or NINO is made up of letters and numbers and never changes. You can find it on your payslip; ' +
              'on your P60; on letters about your tax, pension or benefits; or ' +
              'in the National Insurance section of your <a href="https://www.gov.uk/personal-tax-account">personal tax account</a>.'}
            buttons={[{
              text: 'OK',
              role: 'OK',
              cssClass: 'text-center col-12'
            }]}
          />

          <IonLoading
            isOpen={this.state.showLoading}
            onDidDismiss={() => this.setState({ showLoading: false })}
            message={'Please wait...'}
            duration={5000}
          />
        </IonContent>
      </IonPage >
    );
  }
};

export default Profile;

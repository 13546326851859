import React from 'react';
import { IonApp, IonCol, IonGrid, IonRouterOutlet, IonRow, IonSplitPane, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import SelfEmployment from './pages/SelfEmployment';
import Mileage from './pages/Mileage';
import Profile from './pages/Profile';
import Employment from './pages/Employment';
import EarningsExpenses from './pages/EarningsExpenses';
import Returns from './pages/Returns';
import Login from './pages/Login';
import Help from './pages/Help';
import HMRCPayment from './pages/HMRCPayment';
import Home from './pages/Home';
import Plan from './pages/Plan';
import Covid19Support from './pages/CovidSupport';
import SubmitAuthError from './pages/SubmitAuthError';
import Deserved from './pages/Deserved';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Footer } from "./components/Common";

export const api_url = "api.untied.io";
//export const api_url = "untied1.eu.ngrok.io";

const App: React.FC = () => {

  function isAuthenticated() {
    return localStorage.getItem("token");
  }
  setupConfig({
    rippleEffect: false,
    mode: 'md'
  });

  return (
    <IonApp>
      <IonReactRouter>

        {isAuthenticated() ? (
          <>
            <IonSplitPane contentId="main">
              <Menu />
              <IonGrid className="h-100 d-flex app-container-grid">
                <IonRow className="w-100">
                  <IonCol size="12" className="content-col">
                    <IonRouterOutlet id="main">
                      <Switch>
                        <Route path="/logout" render={() => {
                          console.log('removing token');
                          localStorage.removeItem("selectedYear");
                          localStorage.removeItem("token");
                          window.location.reload(false);
                          return null
                          //return <Redirect to="/login" />
                        }} exact={true} />

                        <Route exact path="/page/TaxFiling/:hasPurchased" render={(props) => {
                          return (<Returns {...props} subscribedWhileSubmit={props.match.params.hasPurchased === 'hasPurchased'} />)
                        }} />

                        <Route exact path="/page/HMRCPayment/form" render={() => {
                          return (<Route component={HMRCPayment} />)
                        }} />

                        <Route path="/page/:name" render={(props) => {
                          if (props.match.params.name === 'Home') {
                            return (<Route component={Home} />)
                          }
                          if (props.match.params.name === 'Profile') {
                            return (<Profile {...props} />)
                          }
                          if (props.match.params.name === 'EarningsExpenses') {
                            return (<EarningsExpenses {...props} />)
                          }
                          if (props.match.params.name === 'SelfEmployment') {
                            return (<SelfEmployment {...props} />)
                          }
                          if (props.match.params.name === 'Mileage') {
                            return (<Mileage {...props} />)
                          }
                          if (props.match.params.name === 'Employment') {
                            return (<Employment {...props} />)
                          }
                          if (props.match.params.name === 'TaxFiling') {
                            return (<Returns {...props} />)
                          }
                          if (props.match.params.name === 'Help') {
                            return (<Route component={Help} />)
                          }
                          if (props.match.params.name === 'HMRCPayment') {
                            return (<Route component={HMRCPayment} />)
                          }
                          if (props.match.params.name === 'cov19') {
                            return (<Route component={Covid19Support} />)
                          }
                          if (props.match.params.name === 'SubmitAuthError') {
                            return (<Route component={SubmitAuthError} />)
                          }
                          if (props.match.params.name === 'Deserved') {
                            return (<Route component={Deserved} />)
                          }
                          if (props.match.params.name === 'Plan') {
                            return (<Plan {...props} />)
                          }
                          return (<Page {...props} />)
                        }} exact={true} />
                        <Route path="/" render={() => { return (<Route component={Home} />) }} exact={true} />
                      </Switch>
                    </IonRouterOutlet>
                  </IonCol>
                  <IonCol size="12" className="footer-col">
                    <Footer />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonSplitPane>

          </>
        ) : (


          <Switch>

            <Route path="/magiclink/:token/:email" render={(props) => {
              return (<Login {...props} />)
            }} />

            <Route path="/page/:name" render={(props) => {
                if (props.match.params.name === 'cov19') {
                  console.log('show covid')
                  return (<Route component={Covid19Support} />)
                } else {
                  return (<Login />)
                }
              }}
            />            

            <Route path="/" render={(props) => {
              return (<Login {...props} />)
            }} />

          </Switch>
  

        )}

      </IonReactRouter>
    </IonApp >
  );
};

export default App;


import React,  { useState } from "react";
import {
    IonContent,
    IonPage,
    IonTitle,
    IonItem,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonAlert,
    IonDatetime,
    IonLabel,
    IonLoading,
    IonText,
    IonPopover,
    IonTextarea
  } from '@ionic/react';
import './Page.scss';
import { CustomButton, NavBar, PageHeader, CustomButtonInverse } from "../components/Common"
import Covid from '../components/Covid'


const Covid19Support = () => {
  const [fromWebView, setFromWebView] = useState(false)


  window.addEventListener("message", function(data){
    if (typeof(data.data) == "string"){
        setFromWebView(true)
    }
}); 
document.addEventListener("message", function(data){
    if (typeof(data.data) == "string"){
        setFromWebView(true)
    }
}); 


       return (
        <IonPage className="selectable">
        {fromWebView ? null : <NavBar label="COVID-19 support" /> }
        <IonContent>
        {fromWebView ? null : <PageHeader label="COVID-19 support" />}
            {Covid()}
        </IonContent>
        </IonPage>
        );

}

 
 export default Covid19Support


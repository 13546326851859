import {
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonHeader,
    IonItem,
    IonLabel,
    IonPopover
} from '@ionic/react';
import React, { useState }  from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { api_url } from "../App"

export const YearSelector = (props) => {
    const today = new Date();
    let selectedYear = props.selectedYear;

    return (
        <IonRow>
          
            <IonCol size="12" className="ion-align-self-center" style={{width:'100%'}}>
                {props.text ? props.text : <h6>Choose tax year:</h6>}
                <Select value={selectedYear}
                        className="year-selector text-center font-weight-bolder"
                        onChange={(event) => {
                            localStorage.setItem("selectedYear", event.target.value);
                            props.onYearChange(event)
                        }}
                        disabled={props.disableField}
                >
                    {props.years.map((element) => {

                        console.log(element)

                        const sd = new Date(element.start_date);
                        const ed = new Date(element.end_date);
                        let label = "";

                        if (today >= sd && today <= ed) {
                            //this_year = element.tax_year;
                            label = " (This Year)"
                            // start_date = element.start_date;
                            // end_date = element.end_date;
                        }

                        const last_year_date = moment(today).subtract(1, "years");
                        if (last_year_date >= sd && last_year_date <= ed) {
                            //last_year = element.tax_year;
                            label = " (Last Year)"
                        }

                        const date_labels = {
                            start: moment(sd).format("D MMM YYYY"),
                            end: moment(ed).format("D MMM YYYY")
                        };

                        return (<MenuItem key={element.id} value={element.id} selected={label === " (Last Year)"}>
                            <span class="date-selector-label">{element.hmrc_year_string.replace('-','/')} - {date_labels.start} to {date_labels.end}</span>
                        </MenuItem>)
                    })}
                </Select>
            </IonCol>
         
        </IonRow>
    )
};

export const TaxSummary = (props) => {

    const [showTaxBreakdown, setShowTaxBreakdown] = useState(false)

    const summary_income = props.taxCalc.summary_income === undefined ? 0 : props.taxCalc.summary_income;
    const summary_expense = props.taxCalc.summary_expense === undefined ? 0 : props.taxCalc.summary_expense;
    const mileage_claimed = props.taxCalc.mileage_claimed === undefined ? 0 : props.taxCalc.mileage_claimed;
    const tax_to_pay = props.taxCalc.tax_to_pay === undefined ? 0 : props.taxCalc.tax_to_pay;
    const ni2 = props.taxCalc.class2_nic === undefined ? 0 : props.taxCalc.class2_nic
    const ni4 = props.taxCalc.class4_nic === undefined ? 0 : props.taxCalc.class4_nic
    const ni = ni2 + ni4

    const tax_to_pay_summary = tax_to_pay + ni

    function renderValue(amount) {
        const a = amount === undefined ? 0 : amount;
        return "£" + Number(a)
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    return (
        <div className="self-employment-container text-center">
            <IonRow>
                <IonCol className="ion-align-self-start">
                    <h6>Income</h6>
                    <span className="income-text">
                        {renderValue(summary_income)}
                    </span>
                </IonCol>
                <IonCol className="ion-align-self-center">
                    <h6>Expenses</h6>
                    <span className="expenses-text">
                        {renderValue(summary_expense + mileage_claimed)}
                    </span>
                </IonCol>
                <IonCol className="ion-align-self-end">
                    <h6>Tax</h6>
                    <span className="fake-anchor" onClick={() => setShowTaxBreakdown(true)}>
                        {renderValue(tax_to_pay_summary)}
                    </span>
                </IonCol>
            </IonRow>

            <IonPopover
                    className="tax-breakdown-alert"
                    isOpen={showTaxBreakdown}
                    onDidDismiss={() => {
                        setShowTaxBreakdown(false)
                    }}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <h5>Tax breakdown</h5>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size={8}>
                                    Tax:
                                </IonCol>
                                <IonCol style={{textAlign:'right'}}>
                                    {renderValue(tax_to_pay)}  
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size={8}>
                                    Class 2 National Insurance:
                                </IonCol>
                                <IonCol style={{textAlign:'right'}}>
                                    {renderValue(ni2)}  
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol size={8}>
                                    Class 4 National Insurance:
                                </IonCol>
                                <IonCol style={{textAlign:'right'}}>
                                    {renderValue(ni4)}  
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol size={8} style={{fontWeight:700}}>
                                    Total:
                                </IonCol>
                                <IonCol style={{textAlign:'right', fontWeight:700}}>
                                    {renderValue(tax_to_pay_summary)}  
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                                <CustomButton
                                    onClick={() => {
                                        setShowTaxBreakdown(false)
                                    }}
                                    label="Close"
                                />
                                </IonCol>
                            </IonRow>

                        </IonGrid>
                                        
            </IonPopover>
        </div>
    )
};

export const TranslateIcon = (props) => {
    function doTranslateLink() {
        let text = document.body.innerText;
        text = text.replace(/untied/g, 'untied®');
        text = text.replace(/untied®®/g, 'untied®');
        text = text.replace(/gig/g, 'GIG');
        let url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
        window.open(url, '_untiedTranslater');
    }
    return (
        <IonButtons>
            <div className="w-100 text-right">
            <IonButton data-tip="Translate page" onClick={() => {
                doTranslateLink()
            }} >
                <span className="translate-text text-capitalize">
                    <div className="translate-flags"/>
                    Translate
                </span>
            </IonButton>
            </div>
        </IonButtons>
    )
};

export const getTaxYears = function (callback) {
    let timeStamp = Math.floor(Date.now() / 1000);
    let token = localStorage.getItem("token");

    if (token == null) {
        return;
    }

    fetch(`https://${api_url}/getTaxYears.php?ts=${timeStamp}`, {
        method: "GET",
        headers: { token: token }
    })
        .then(res => res.json())
        .then(json => {

            if (json.error) {
                if (json.error === "unauthorized") {
                    //alert('redirect to login page');
                    window.location = '/logout';
                }
            } else {

                const localYear = localStorage.getItem("selectedYear");
                var element = {}
                if (localYear) {
                    element = json.years.find(element => {
                        return (element.id === localYear);
                    })
                } else {
                    const last_year_date = moment(new Date()).subtract(1, "years");
                    element = json.years.find(element => {
                        return (last_year_date >= moment(element.start_date) && last_year_date <= moment(element.end_date));
                    })
                }

                callback(json.years, element)
            }

        });
};

export const getTaxCalc = function (taxYearId, callback) {
    let timeStamp = Math.floor(Date.now() / 1000);
    const token = localStorage.getItem("token");
    // const taxYearId = this.state.selectedYear;
    fetch(`https://${api_url}/getTaxCalculation.php?ts=${timeStamp}&tax_year_id=${taxYearId}`, {
        method: "GET",
        headers: { token: token }
    })
        .then(res => res.json())
        .then(json => {

            if (json.error) {
                if (json.error === "unauthorized") {
                    //alert('redirect to login page');
                    window.location = '/logout';
                }
            } else {
                callback(json)
            }

        });
};

export const NavBar = () => (
    <IonHeader>
        <IonToolbar>
            <div className="ml-2 banner-container text-center">
                <div className="banner-white"/>
                <span>The UK's personal tax app</span>
            </div>
                <IonButtons slot="end">
                <IonMenuButton />
            </IonButtons>
        </IonToolbar>
    </IonHeader>
);

export const PageHeader = (props) => (
    <IonHeader className="page-header">
        <IonToolbar>
            <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
                <IonRow>
                    <IonCol size-sm="10">
                        <IonTitle size="large" className="strong-text page-header-title">{props.label}</IonTitle>
                    </IonCol>
                    <IonCol size-sm="2" className="translate-container">
                        <TranslateIcon />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    </IonHeader>
);

export const CustomButton = (props) => {
    const {linkTo, label, onClick, hidden, color, small, big, style} = props;

    return(
        <IonButton onClick={onClick || null} className={`ion-button-money w-100 m-auto ${big ? 'subscription-button text-lowercase' : ''} ${small ? 'small-text' : ''} ${hidden ? 'd-none' : ''}`} style={props.style?props.style:{}}>
            <IonItem color={color} className={`employment-pages-nav-button w-100 ion-activatable ${big ? 'd-flex h-100' : ''}`} href={linkTo}>
                <IonLabel className={`text-center ${big ? 'ion-text-wrap' : ''}`}>{label}</IonLabel>
            </IonItem>
        </IonButton>
    )
};

export const CustomButtonInverse = (props) => {
    const {linkTo, label, onClick, hidden, color, small, big} = props;

    return(
        <IonButton href={linkTo} onClick={onClick || null} className={`ion-button-inverse w-100 m-auto ${big ? 'subscription-button text-lowercase' : ''} ${small ? 'small-text' : ''} ${hidden ? 'd-none' : ''}`}>
                <IonItem color={color} className={`employment-pages-nav-button w-100 ion-activatable ${big ? 'd-flex h-100' : ''}`} href={linkTo}>
                    <IonLabel style={{"--color": "#3f90b0"}} className={`text-center ${big ? 'ion-text-wrap' : ''} blue-text`}>{label}</IonLabel>
                </IonItem>
        </IonButton>
    )
};

export const Footer = () => (
    <footer className="text-center">
        <IonGrid>
            <IonRow>
                <IonCol>
                    <span className="notranslate">© 2019-2021 untied</span>{" | "}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.untied.io/untied-privacy-policy/">Privacy Policy</a>
                    {" | "}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.untied.io/untied-app-terms-conditions/">Terms &amp; Conditions</a>
                    {" | "}
                    <a href="javascript:void()" onClick={() => window.Beacon('open')}>Help</a>
                </IonCol>
            </IonRow>
        </IonGrid>
    </footer>
);
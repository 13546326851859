import {
    IonContent, IonPage, IonList, IonItem,
    IonItemSliding, IonIcon, IonItemOptions, IonItemOption, IonGrid, IonRow, IonCol, IonInput,
    IonSelect, IonSelectOption, IonAlert, IonLoading
} from '@ionic/react';
import React, { Component } from "react";
import moment from "moment";
import { trashOutline, } from 'ionicons/icons';
import {
    YearSelector,
    TaxSummary,
    getTaxYears,
    getTaxCalc,
    NavBar,
    PageHeader,
    CustomButton,
    CustomButtonInverse
} from "../components/Common"
import { api_url } from "../App"
import { Mixpanel } from "./mixpanel";

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import './Page.scss';
import Collapsible from 'react-collapsible';

class Mileage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "2B. Mileage",
            years: [],
            selectedYear: "-1",
            selectedYearData: {},
            journeys: [],
            taxCalc: {},
            journeyType: 'in_vehicle',
            journeyMiles: "",
            journeyDescription: "",
            showErrorBox: false,
            addJourney: false,
            itemTop: false
        };

    }

    componentDidMount() {
        Mixpanel.track("loaded_mileage");
        getTaxYears((years, yearInfo) => {
            this.setState({ years, selectedYear: yearInfo.id, selectedYearData: yearInfo },
                () => {
                    this.getJourneys();
                    getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
                }
            );
        });

        window.onpopstate = (e) => {
            if (this.state.addJourney === true) {
                this.setState({ addJourney: false })
            }
        };
    }

    getJourneys() {
        var timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        const fromDate = this.state.selectedYearData.start_date;
        const toDate = this.state.selectedYearData.end_date + 'T23:59:59:59Z'

        fetch(`https://${api_url}/getJourneys.php?ts=${timeStamp}&timefrom=${fromDate}&timeto=${toDate}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        this.props.history.replace("/logout");
                    }
                } else {
                    this.setState(
                        {
                            journeys: json.journeys,
                        }
                    );
                }
            });
    }

    addJourney() {
        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        const start_postcode = 'UNKNOWN';
        const end_postcode = 'UNKNOWN';
        const claimed_miles = this.state.journeyMiles;
        const comments = this.state.journeyDescription;
        const vehicle = this.state.journeyType;
        var isodate;

        if ((comments === "") || (claimed_miles === "")) {
            this.setState({ showErrorBox: true })
            return;
        }

        //if we have journeys use date of last + 1 ms : 
        if (this.state.journeys.length === 0) {
            isodate = moment(new Date(this.state.selectedYearData.end_date)).toISOString();
            //const endisodate = isodate;
        } else {
            var last_time = this.state.journeys[0].start_time;
            isodate = moment(last_time).add(1, 'ms').toISOString();
        }

        this.setState({ showLoading: true })
        fetch(
            `https://${api_url}/addJourney.php?ts=${timeStamp}&date=${isodate}&enddate=${isodate}` +
            `&start_postcode=${start_postcode}&end_postcode=${end_postcode}&miles=${claimed_miles}` +
            `&comments=${comments}&vehicle=${vehicle}`,
            {
                headers: {
                    token: token
                }
            }
        )
            .then(res => res.json())
            .then(json => {
                //claim it ...
                fetch(
                    `https://${api_url}/claimJourney.php?ts=${timeStamp}&id=${json.id}&miles=${claimed_miles}&comments=${comments}`,
                    {
                        method: 'POST',
                        headers: { token: token }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        Mixpanel.track("added_journey");
                        this.getJourneys();
                        getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
                        this.setState({ addJourney: false, journeyType: 'in_vehicle', journeyDescription: '', journeyMiles: '', showLoading: false })
                    })
            });

    }

    deleteJourney(journey_id) {

        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        this.setState({ showLoading: true })
        fetch(
            `https://${api_url}/discardJourney.php?ts=${timeStamp}&id=${journey_id}`,
            {
                method: "GET",
                headers: { token: token }
            }
        )
            .then(res => res.json())
            .then(json => {

                document.querySelector("ion-item-sliding").closeOpened();
                this.getJourneys();
                getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json, showLoading: false }) });

            });
    }

    handleYearChange = (event) => {
        const yearElement = this.state.years.find(element => {
            return element.id === event.target.value
        })
        Mixpanel.track("changed_year", yearElement);

        this.setState({ selectedYear: event.target.value, selectedYearData: yearElement }, () => {
            this.getJourneys();
            getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
        });
    }

    renderAmount(element) {
        const prefix = element.type === 'CREDIT' ? "+ £" : "£"
        return (
            prefix +
            Number(element.amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
    }

    prettyDistance(dist){
        if (dist.length<4){
            return dist
        }
        else{
        let value = dist.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return value
        }
    }

    prettyDateTime(dtstr) {
        var date1 = new Date(dtstr);
        var longdate = date1.toDateString();
        //  var timepart = "";
        //var timepart = date1.toLocaleTimeString();
        // return longdate + " " + timepart;
        return longdate;
    }

    showButtons() {
        return (
            <IonRow>
                <IonCol size-sm="3" />
                <IonCol size-sm="6">
                    <CustomButton
                        onClick={() => {
                            this.setState({ addJourney: true });
                            //window.history.pushState('journey form', 'Mileage', '/page/Mileage/form');
                        }}
                        label="Add mileage"
                    />
                </IonCol>
                <IonCol size-sm="3" />
            </IonRow>
        )
    }

    renderVehicleType(vehicle_type)  {

        switch(vehicle_type) {
            case 'in_vehicle':
              return  <DirectionsCarIcon className="journey-icon"/>;
            case 'on_bicycle':
                return  <DirectionsBikeIcon className="journey-icon"/>;
            case 'on_motorcycle':
                 return  <MotorcycleIcon className="journey-icon"/>;
            default:
              return <DirectionsCarIcon className="journey-icon"/>;
          }

    }

    showJourneys() {
        return (
            <div>
                <IonList>
                    {this.state.journeys.map(element => {
                        if (element.claimed === 'Y') {
                            return (
                                <IonItemSliding key={element.ndx}>
                                    <IonItem style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                        <IonGrid>
                                            <IonRow style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                                
                                                <IonCol style={{ textAlign: 'left' }}>
                                                  
                                                        {this.renderVehicleType(element.type)} 
                                                        {element.comments || this.prettyDateTime(element.start_time)}
                                        
                                                </IonCol>
                                                <IonCol style={{ textAlign: 'right' }}>{this.prettyDistance(element.miles_claimed)} miles</IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItemOptions side="end">
                                        <IonItemOption onClick={() => { this.deleteJourney(element.id) }}><IonIcon slot="start" icon={trashOutline} color="white" /> Delete</IonItemOption>
                                    </IonItemOptions>
                                </IonItemSliding>
                            )
                        } else {
                            return <div />
                        }
                    })}

                </IonList>

                {this.state.journeys.length > 0 && (
                    <IonGrid>
                        <IonRow>
                            <IonCol className="text-center">
                                <p style={{fontStyle:'italic', color: '#8A8A8A' }}>To delete an item swipe left</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </div>
        )

    }

    showAddJourneyForm() {
        return (
            <div>
                <IonAlert
                    isOpen={this.state.showErrorBox}
                    onDidDismiss={() => this.setState({ showErrorBox: false })}
                    header={'Oops'}
                    subHeader={'Information required'}
                    message={'Please enter a description of the journey and the number of miles to claim.'}
                    buttons={[{
                        text: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <p className="text-center">Add Mileage</p>
                <IonGrid fixed={true}>
                    <IonRow>
                        <IonCol className="text-center">
                            <IonItem className="input-container">
                                <IonSelect value={this.state.journeyType} interface="popover"
                                    onIonChange={(event) => {
                                        this.setState({ journeyType: event.target.value })
                                    }
                                    }>
                                    <IonSelectOption value="in_vehicle" selected={true}>By car or van</IonSelectOption>
                                    <IonSelectOption value="on_motorcycle">By motorbike or scooter</IonSelectOption>
                                    <IonSelectOption value="on_bicycle">By bicycle</IonSelectOption>
                                </IonSelect>
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonInput value={this.state.journeyDescription}
                                    onIonChange={e => this.setState({ journeyDescription: e.detail.value })}
                                    placeholder="Description" inputmode="text" type="text" />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                            <IonItem className="money-input input-container">
                                <IonInput value={this.state.journeyMiles}
                                    onIonChange={e => this.setState({ journeyMiles: e.detail.value })}
                                    placeholder="Miles" inputmode="numeric" type="number" />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size-sm="4">
                            <CustomButtonInverse
                                onClick={() => {
                                    this.setState({ addJourney: false });
                                }}
                                label="Cancel"
                               
                                small
                            />
                        </IonCol>
                        <IonCol size-sm="4" />
                        <IonCol size-sm="4">
                            <CustomButton
                                onClick={() => {
                                    this.addJourney();
                                }}
                                label="Add Mileage"
                                small
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonLoading
                    isOpen={this.state.showLoading}
                    onDidDismiss={() => this.setState({ showLoading: false })}
                    message={'Please wait...'}
                    duration={5000}
                />
            </div>
        )
    }


    render() {
        return (
            <IonPage cache-view="false" className="selectable">
                <NavBar label={this.state.title} />

                <IonContent>
                    <PageHeader label="Mileage" />

                    <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>
                        <IonRow>
                            <IonCol>
                                <Collapsible
                                    trigger={(
                                        () => (
                                            <div className="mt-0">
                                                <p>Tell us how many miles you drove or rode in total for your self-employment. <span className="strong-text">untied will automatically work out the rate</span> that HMRC allows you to claim.</p>
                                            </div>
                                        )
                                    )()}
                                    containerElementProps={{ id: "itemTop" }}
                                    onOpen={() => {
                                        this.setState({ itemTop: true });
                                    }}
                                    onClose={() => {
                                        this.setState({ itemTop: false });
                                    }}
                                >
                                </Collapsible>

                                <YearSelector onYearChange={(event) => this.handleYearChange(event)} years={this.state.years} selectedYear={this.state.selectedYear} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <TaxSummary taxCalc={this.state.taxCalc} />
                            </IonCol>
                        </IonRow>

                        {!this.state.addJourney && this.showButtons()}
                        {!this.state.addJourney && this.showJourneys()}
                        {this.state.addJourney && this.showAddJourneyForm()}

                        <IonRow>
                            <IonCol size-sm="3" />
                            <IonCol size-sm="6" className="ion-align-self-start text-center">
                                <CustomButton
                                    linkTo="/page/Employment"
                                    label="Next"
                                    hidden={this.state.addJourney}
                                />
                            </IonCol>
                            <IonCol size-sm="3" />
                        </IonRow>
                    </IonGrid>
                </IonContent >
            </IonPage >
        );
    }
}


export default Mileage;

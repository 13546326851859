import {
    IonContent, IonPage, IonList, IonItem,
    IonItemSliding, IonIcon, IonItemOptions, IonItemOption, IonGrid, IonRow, IonCol, IonInput,
    IonSelect, IonSelectOption, IonAlert, IonLoading
} from '@ionic/react';
import React, { Component } from "react";
import moment from "moment";
import { trashOutline, } from 'ionicons/icons';
import {
    YearSelector,
    TaxSummary,
    getTaxYears,
    getTaxCalc,
    NavBar,
    PageHeader, CustomButton, CustomButtonInverse
} from "../components/Common"
import { api_url } from "../App"
import { Mixpanel } from "./mixpanel";
import Collapsible from 'react-collapsible';
import './Page.scss';


class SelfEmployment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "2A. Self Employment",
            years: [],
            selectedYear: "-1",
            selectedYearData: {},
            transactions: [],
            taxCalc: {},
            transactionDescription: '',
            otherDescription: '',
            transAmount: '',
            showErrorBox: false,
            errorText: "",
            addTransaction: false,
            itemTop: false
        };

    }

    componentDidMount() {
        Mixpanel.track("loaded_self_employment");
        getTaxYears((years, yearInfo) => {
            this.setState({ years, selectedYear: yearInfo.id, selectedYearData: yearInfo },
                () => {
                    this.getTransactions();
                    getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
                }
            );
        });

        window.onpopstate = (e) => {
            if (this.state.addTransaction === true) {
                this.setState({ addTransaction: false })
            }
        };

    }

    getTransactions() {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        const fromDate = this.state.selectedYearData.start_date
        const toDate = this.state.selectedYearData.end_date

        const filter = "%20and%20ht_accounts.name%20%3D%20%27Cash%27%20";

        fetch(`https://${api_url}/getTransactions.php?ts=${timeStamp}&from=${fromDate}&to=${toDate}&filter=${filter}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        this.props.history.push("/logout");
                    }
                } else {

                    //find the trading categories (TRAE and TRAI)
                    const trae_element = json.tax_categories.find(function (e) { return e.code === 'TRAE' });
                    const trai_element = json.tax_categories.find(function (e) { return e.code === 'TRAI' });
                    const seis_element = json.tax_categories.find(function (e) { return e.code === 'SEIS' });

                    this.setState(
                        {
                            transactions: json.transactions,
                            tax_cats: json.tax_categories,
                            filterBy: this.state.filterBy,
                            creditTaxCat: trai_element.id,
                            debitTaxCat: trae_element.id,
                            seisTaxCat: seis_element.id
                        }
                    );
                }
            });
    }

    addTransaction() {

        if (this.state.transactionDescription === "") {
            this.setState({ showErrorBox: true, showLoading: false, errorText: "Please select an income source." })
            return;
        }

        if (this.state.transactionDescription === "Other" && this.state.otherDescription === "") {
            this.setState({ showErrorBox: true, showLoading: false, errorText: "Please describe the income source." })
            return;
        }

        if (this.state.transAmount === "") {
            this.setState({ showErrorBox: true, showLoading: false, errorText: "Please enter the amount. Enter numbers and decimal point only." })
            return;
        }


        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        const trans_type = this.state.addType === 'income' ? 'In' : 'Out';
        const category = 
            this.state.transactionDescription === 'SEISS grant' 
                ? this.state.seisTaxCat
                : this.state.addType === 'income' ? this.state.creditTaxCat : this.state.debitTaxCat;
        
        let description = this.state.transactionDescription === 'Other' ? this.state.otherDescription : this.state.transactionDescription;
        description = description + ' ' + this.state.addType.charAt(0).toUpperCase() + this.state.addType.slice(1)
        if (this.state.transactionDescription!="Other" &&  this.state.otherDescription!="" ) {
            description = description + ' - ' +  this.state.otherDescription; 
            console.log(description)
        }

        const amount = this.state.transAmount
        const trans_date = moment(new Date(this.state.selectedYearData.end_date)).format("DD/MM/YYYY");

        const payload = { description, amount, trans_type, trans_date, category }

        fetch(`https://${api_url}/createTransaction.php?ts=${timeStamp}`, {
            method: "POST",
            headers: { token: token },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                Mixpanel.track("added_self_employment_transaction");
                this.getTransactions();
                getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
                this.setState({ addTransaction: false, transAmount: '', otherDescription: '', transactionDescription: '', showLoading: false })
            });

    }

    deleteTransaction(trans_id) {

        this.setState({ showLoading: true });
        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        fetch(
            `https://${api_url}/unclaimTransaction.php?ts=${timeStamp}&trans_id=${trans_id}`,
            {
                method: "GET",
                headers: { token: token }
            }
        )
            .then(res => res.json())
            .then(json => {

                fetch(
                    `https://${api_url}/discardTransaction.php?ts=${timeStamp}&id=${trans_id}`,
                    {
                        method: "GET",
                        headers: { token: token }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        Mixpanel.track("deleted_self_employment_transaction");
                        document.querySelector("ion-item-sliding").closeOpened();
                        this.getTransactions();
                        getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json, showLoading: false }) });
                    });
            });
    }

    handleYearChange = (event) => {
        const yearElement = this.state.years.find(element => {
            return element.id === event.target.value
        })
        Mixpanel.track("changed_year", yearElement);

        this.setState({ selectedYear: event.target.value, selectedYearData: yearElement }, () => {
            this.getTransactions();
            getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
        });
    }

    renderAmount(element) {
        const prefix = element.type === 'CREDIT' ? "+ £" : "- £"
        // const prefix = "£"
        return (
            prefix +
            Math.abs(element.amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
    }

    prettyDateTime(dtstr) {
        var date1 = new Date(dtstr);
        var longdate = date1.toDateString();
        //  var timepart = "";
        //var timepart = date1.toLocaleTimeString();
        // return longdate + " " + timepart;
        return longdate;
    }

    showButtons() {
        return (
            <IonRow>
                <IonCol size-sm="4">
                    <CustomButton
                        onClick={() => {
                            this.setState({ addTransaction: true, addType: 'income' });
                            //window.history.pushState('income form', 'Self Employment', '/page/SelfEmployment/form');
                        }}
                        label="Add Income"
                        small
                    />
                </IonCol>
                <IonCol size-sm="4" />
                <IonCol size-sm="4">
                    <CustomButton
                        onClick={() => {
                            this.setState({ addTransaction: true, addType: 'expense' });
                            //window.history.pushState('expense form', 'Self Employment', '/page/SelfEmployment/form');
                        }}
                        label="Add Expense"
                        small
                    />
                </IonCol>
            </IonRow>
        )
    }

    showTransactions() {
        return (
            <div>
                <IonList>

                    {this.state.transactions.map(element => {
                        return (
                            <IonItemSliding key={element.ndx}>
                                <IonItem style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                    <IonGrid>
                                        <IonRow style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                            {/* <IonCol className="text-center">{this.prettyDateTime(element.datetime)}</IonCol> */}
                                            <IonCol>{element.description}</IonCol>
                                            <IonCol 
                                                style={{
                                                    textAlign: 'right',
                                                    color: element.type === 'CREDIT' ? 'green' : '#A800FF'
                                                }}>{this.renderAmount(element)}</IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>

                                <IonItemOptions side="end">
                                    <IonItemOption onClick={() => { this.deleteTransaction(element.id) }}><IonIcon slot="start" icon={trashOutline} color="white" /> Delete</IonItemOption>
                                </IonItemOptions>
                            </IonItemSliding>
                        )
                    })}

                </IonList>

                {this.state.transactions.length > 0 && (
                    <IonGrid>
                        <IonRow>
                            <IonCol className="text-center">
                                <p style={{fontStyle:'italic', color: '#8A8A8A' }}>To delete an item swipe left</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </div>
        )
    }

    showAddTransactionForm() {
        return (
            <div>
                <IonAlert
                    isOpen={this.state.showErrorBox}
                    onDidDismiss={() => this.setState({ showErrorBox: false })}
                    header={'Oops'}
                    subHeader={'Information required'}
                    message={this.state.errorText}
                    buttons={[{
                        text: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <p className="text-center">Add {this.state.addType.charAt(0).toUpperCase() + this.state.addType.slice(1)}</p>
                <IonGrid fixed={true}>
                    <IonRow>
                        <IonCol className="text-center">
                            <IonItem className="input-container">
                                <IonSelect value={this.state.transactionDescription} 
                                interface="popover"
                                interfaceOptions={{
                                    className: "employment-popover",
                                }}
                                    onIonChange={(event) => {
                                        this.setState({ transactionDescription: event.target.value })
                                    }
                                    }>
                                    <IonSelectOption value="" selected={true}>Select {this.state.addType} source ... </IonSelectOption>
                                    <IonSelectOption value="Deliveroo">Deliveroo {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Amazon/Amazon Flex">Amazon/Amazon Flex {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Just Eat">Just Eat {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Uber">Uber {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Uber Eats">Uber Eats {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Free Now">Free Now {this.state.addType}</IonSelectOption>
                                    <IonSelectOption value="Stuart">Stuart {this.state.addType}</IonSelectOption>
                                    {this.state.addType.toLowerCase() === "income" && <IonSelectOption value="SEISS grant">SEISS grant</IonSelectOption>}
                                    <IonSelectOption value="Other">Other {this.state.addType}</IonSelectOption>
                                </IonSelect>
                            </IonItem >
                        </IonCol>
                    </IonRow>
                  
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonInput value={this.state.otherDescription}
                                        onIonChange={e => this.setState({ otherDescription: e.detail.value })}
                                        placeholder="Description" inputmode="text" type="text" />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                
                    <IonRow>
                        <IonCol className="ion-align-self-center">
                            <IonItem className="money-input input-container">
                                <IonInput value={this.state.transAmount}
                                    onIonChange={e => this.setState({ transAmount: e.detail.value })}
                                    placeholder="Amount" inputmode="numeric" type="number" />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size-sm="4">
                            <CustomButtonInverse
                                onClick={() => {
                                    this.setState({ addTransaction: false });
                                }}
                                label="Cancel"
                                small
                            
                            />
                        </IonCol>
                        <IonCol size-sm="4" />
                        <IonCol size-sm="4">
                            <CustomButton
                                onClick={() => {
                                    this.setState({ showLoading: true });
                                    this.addTransaction();
                                }}
                                label={`Add ${this.state.addType}`}
                                small
                            />
                        </IonCol>
                    </IonRow>

                </IonGrid>

                <IonLoading
                    isOpen={this.state.showLoading}
                    onDidDismiss={() => this.setState({ showLoading: false })}
                    message={'Please wait...'}
                    duration={5000}
                />
            </div >
        )
    }


    render() {
        return (
            <IonPage cache-view="false" className="selectable">
                <NavBar label={this.state.title} />

                <IonContent>
                    <PageHeader label="Self-employment" />

                    <IonGrid className="ml-2 mr-2 m-auto mt-0 pt-0" fixed={true}>
                        <IonRow>
                            <IonCol>

                            <p> <span className="strong-text">Enter how much you earned</span> - this could be for the whole year, or certain periods of time (you can add a description under “Other income”).
Do the same with your <span className="strong-text">expenses</span>.
                                                   
                                                </p>

                                <Collapsible
                                    trigger={(
                                        () => (
                                            <div className="mt-0">
                                                <h5> Some tips
                                                    <i className={!this.state.itemTop ? "collapsed" : "expanded"} />
                                                </h5>
                                            </div>
                                        )
                                    )()}
                                    containerElementProps={{ id: "itemTop" }}
                                    onOpen={() => {
                                        this.setState({ itemTop: true });
                                    }}
                                    onClose={() => {
                                        this.setState({ itemTop: false });
                                    }}
                                >
                                    <div>
                                        <p> - Many platforms will give you a statement of how much you earned in a certain period.</p>

                                        <p> - Your <span style={{fontWeight:700}}>expenses</span> will include platform deductions (if it’s going to a pension you may need untied Pro), your rider insurance, payments to substitutes and equipment. </p>

                                        <p> - If you use your phone for business, claim that.</p>  

                                        <p> - Don’t forget to <span style={{fontWeight:700}}>add the cost of your untied subscription!</span></p>

                                        <p> - Vehicle costs can either be claimed quickly and easily in the next section - <span style={{fontWeight:700}}>Mileage</span>.</p>

                                        <p> - Or you can calculate the business proportion of your vehicle and claim that as expenses(not recommended for cars). </p>

                                        <p> - Don’t double claim though. </p>

                                        <p>Check out the <a href="https://help.untied.io/category/4-faqs" target="_new">FAQs</a> on our website for more information.</p>


                                    </div>
                                </Collapsible>

                                <YearSelector onYearChange={(event) => this.handleYearChange(event)} years={this.state.years} selectedYear={this.state.selectedYear} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <TaxSummary taxCalc={this.state.taxCalc} />
                            </IonCol>
                        </IonRow>

                        {!this.state.addTransaction && this.showButtons()}
                        {!this.state.addTransaction && this.showTransactions()}
                        {this.state.addTransaction && this.showAddTransactionForm()}

                        <IonRow>
                            <IonCol size-sm="3" />
                            <IonCol size-sm="6" className="ion-align-self-start text-center">
                                <CustomButton
                                    linkTo="/page/Mileage"
                                    label="Next"
                                    hidden={this.state.addTransaction}
                                />
                            </IonCol>
                            <IonCol size-sm="3" />
                        </IonRow>
                        <IonRow /><IonCol />
                    </IonGrid>
                </IonContent >
            </IonPage >
        );
    }
}


export default SelfEmployment;

import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonIcon,
    IonItem,
    IonAlert,
    IonLoading
} from '@ionic/react';
import React, { useEffect, useState } from "react";
import { NavBar, PageHeader, CustomButton } from "../components/Common"
import { api_url } from "../App";
import { informationCircleSharp } from "ionicons/icons";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Page.scss';


const HMRCPayment = () => {
    const [screen, setScreen] = useState("initial");
    const [banks, setBanks] = useState([]);
    const [showUTR, setShowUTR] = useState(false);
    const [formData, setFormData] = useState({ userProfile: {}, utr: "", amount: "", bankId: "" });
    const [picUrl, setPicUrl] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const target_account = ['HMRC Cumbernauld', '083210', '12001039'];
    const getProfile = () => {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${api_url}/getUserProfile.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                setFormData({
                    userProfile: json,
                    utr: json.utr,
                    amount: formData.amount,
                    bankId: formData.bankId
                });
            })
    };
    const getBanks = () => {
        fetch(`https://${api_url}/fractal/getBanks.php`)
            .then(res => res.json())
            .then(json => {
                setBanks(json);
            })
    };
    const getPicUrl = (id) => {
        const obj = banks.filter((bank) => (bank.fractal_id.toString() === id.toString()))[0];
        setPicUrl(obj.iconUrl || obj.logoUrl);
    };
    const saveUserUTR = () => {
        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        let payload = formData.userProfile;
        payload.utr = formData.utr;

        fetch(`https://${api_url}/saveUserProfile.php?ts=${timeStamp}`, {
            method: "POST",
            headers: { token: token },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                getProfile();
            })
    };
    const pay = () => {
        const payload = {
            bank_id: formData.bankId,
            name: target_account[0],
            sort_code: target_account[1],
            account_number: target_account[2],
            reference: formData.utr + 'K',
            amount: formData.amount
        };
        setShowLoading(true);
        fetch(`https://${api_url}/fractal/getPaymentAuthLink.php`,
            { method: "POST", body: JSON.stringify(payload) }
        )
            .then(res => res.json())
            .then(json => {
                if (json.status === 'success') {
                    const authUrl = json.authUrl;
                    window.location = authUrl
                }
            })
    };
    const checkScreen = (str) => {
        if (!str.includes("/form") && screen === "form") {
            setScreen("initial");
            setShowLoading(false);
        }
        if (str.includes("/form") && screen === "initial") {
            setScreen("form");
            setShowLoading(false);
            setError(false);
        }
    };
    const validForm = () => {
        return formData.utr !== "" && formData.amount !== "" && formData.bankId !== ""
    };
    const buildInitialScreen = () => (<>
        <IonRow>
            <IonCol>
                <h6>untied grants you <span className="strong-text">peace of mind</span> when <span className="strong-text">paying your taxes to HMRC</span>.</h6>
                <h6>Together with our partners <a href="https://www.askfractal.com/" target="_blank" rel="noopener noreferrer"><span className="strong-text">FRACTAL LABS</span></a>, <span className="strong-text">untied assures</span> that your personal <span className="strong-text">tax payment reaches HMRC</span>.</h6>

                <h6 className="mt-3">We only need to know:</h6>
                <h6>1. Your Unique Taxpayer Reference</h6>
                <h6>2. Your bank</h6>
                <h6>3. The amount you wish to pay to HMRC</h6>

                <h6 className="mt-3">You’ll be securely transferred to <span className="strong-text">FRACTAL LABS to authenticate with your bank and make the payment</span>.</h6>
                <h6 className="mt-3">We'll route your payment to HMRC via their default account for personal taxes, regardless of your tax office.</h6>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol size-sm="3" />
            <IonCol size-sm="6">
                <CustomButton
                    label="Get started"
                    onClick={() => {
                        setScreen("form");
                        window.history.pushState(null, "HMRC Payment", "/page/HMRCPayment/form")
                    }}
                />
            </IonCol>
            <IonCol size-sm="3" />
        </IonRow>
    </>);
    const buildFormScreen = () => (<>
        <IonRow>
            <IonCol>
                <IonItem className="input-container">
                    <IonLabel position="floating">Unique Taxpayer Reference:</IonLabel>
                    <IonInput
                        type="text"
                        placeholder=""
                        value={formData.utr}
                        onIonChange={e => {
                            setFormData({
                                userProfile: formData.userProfile,
                                utr: e.detail.value,
                                amount: formData.amount,
                                bankId: formData.bankId
                            })
                        }} />
                    <IonIcon color="primary" className="mr-0" slot="end" icon={informationCircleSharp}
                        onClick={() => {
                            setShowUTR(true)
                        }}
                    />
                </IonItem>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <IonItem className="input-container">
                    <IonLabel position="floating">Amount you are paying to HMRC</IonLabel>
                    <IonInput type="number" value={formData.amount} onIonChange={(e) => {
                        setFormData({
                            userProfile: formData.userProfile,
                            utr: formData.utr,
                            amount: e.detail.value,
                            bankId: formData.bankId
                        })
                    }} />
                </IonItem>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <IonItem className="input-container">
                    <Autocomplete
                        {...bankSelectorProps}
                        className="bank-selector"
                        autoComplete
                        includeInputInList
                        onChange={(e, value) => {
                            setFormData({
                                userProfile: formData.userProfile,
                                utr: formData.utr,
                                amount: formData.amount,
                                bankId: value ? value.fractal_id : ""
                            });
                            if (value) {
                                getPicUrl(value.fractal_id);
                            }
                        }}
                        renderInput={(params) => {
                            return (<div className={`d-inline-flex w-100 ${formData.bankId ? "selected" : ""}`}>
                                <div className={formData.bankId ? "d-flex" : "d-none"}>
                                    <div style={{
                                        height: "20px",
                                        width: "20px",
                                        backgroundImage: `url(${picUrl})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        marginTop: "auto",
                                        marginBottom: "6px",
                                        marginRight: "10px"
                                    }} />
                                </div>
                                <TextField {...params} label="Choose your bank" placeholder="Search and select" margin="normal" />
                            </div>)
                        }}
                        renderOption={(option) => {
                            return (
                                <div className="d-flex">
                                    <div style={{
                                        height: "20px",
                                        width: "20px",
                                        backgroundImage: `url(${option.iconUrl || option.logoUrl})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain"
                                    }} />
                                    <div className="d-flex align-items-center ml-3">{option.name}</div>
                                </div>
                            )
                        }}
                    />
                </IonItem>

            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol size-sm="3" />
            <IonCol size-sm="6">
                <CustomButton
                    onClick={() => {
                        if (validForm()) {
                            saveUserUTR();
                            pay();
                        } else {
                            setError(true);
                        }
                    }}
                    label="Next"
                />
            </IonCol>
            <IonCol size-sm="3" />
        </IonRow>
        <IonRow>
            <IonCol>
                <p className="text-center">Pressing "Next" will safely take you to your bank, where you can authorise this payment.</p>
            </IonCol>
        </IonRow>
    </>);

    const bankSelectorProps = {
        options: banks,
        getOptionLabel: (banks) => banks.name,
    };

    useEffect(() => {
        getProfile();
        getBanks();
    }, []);

    return (
        <IonPage className="selectable">
            <NavBar />
            <IonContent>
                <PageHeader label="Pay HMRC" />
                <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
                    {checkScreen(window.location.pathname)}
                    {screen === "initial" && buildInitialScreen()}
                    {screen === "form" && buildFormScreen()}
                </IonGrid>
            </IonContent>


            <IonAlert
                isOpen={showUTR}
                onDidDismiss={() => {
                    setShowUTR(false)
                }}
                header={"Unique Tax Reference"}
                subHeader={""}
                message={'UTR stands for Unique Taxpayer Reference.  Your UTR is a unique number that identifies you to HMRC. ' +
                    'Your UTR number is assigned automatically by HMRC when you register for self assessment tax returns. ' +
                    'If you are not yet registered for self assessment you will need to do so if you need to submit a tax return. ' +
                    'You can do that <a href="https://www.gov.uk/log-in-file-self-assessment-tax-return/register-if-youre-not-self-employed">here on the HMRC website</a>.'}
                buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'shadow text-center col-12'
                }]}
            />
            <IonAlert
                isOpen={error}
                onDidDismiss={() => {
                    setError(false)
                }}
                header={"Payment form has errors"}
                subHeader={""}
                message={'Please note all fields are mandatory.'}
                buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'shadow text-center col-12'
                }]}
            />
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={10000}
            />
        </IonPage >
    );
};

export default HMRCPayment;

import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonAlert,
    IonLoading,
    IonCheckbox,
    IonLabel,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonTextarea
} from '@ionic/react';
import { informationCircleSharp, openOutline } from 'ionicons/icons';

import React, { Component } from "react";
import moment from "moment";
import {
    YearSelector,
    TaxSummary,
    getTaxYears,
    getTaxCalc,
    NavBar,
    PageHeader, CustomButton, CustomButtonInverse
} from "../components/Common"
import { api_url } from "../App"
import { Mixpanel } from "./mixpanel";
import Collapsible from 'react-collapsible';

import './Page.scss';
import { TransferWithinAStation } from '@material-ui/icons';

class Returns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            years: [],
            selectedYear: "-1",
            selectedYearData: {},
            returns: [],
            taxCalc: {},
            showErrorBox: false,
            useAgent: false,
            data: null,
            profile: { name: "", address: "", postcode: "" },
            hmrcPassword: "",
            hmrcUsername: "",
            processingSubmission: false,
            submitIsDone: false,
            dotil: false,
            sentEmail: false,
            showUTRinfo: false,
            covidSupport: false,

            //debug - remove later!
            // success:'Y',
            // submitIsDone: true,
            // data: 'TEST HERE'            
            //    //end

        };
    }

    componentDidMount() {
        Mixpanel.track("loaded_returns");
        const hmrcUsername = localStorage.getItem("HMRCUsername");
        this.setState({ hmrcUsername });
        getTaxYears((years, yearInfo) => {
            this.setState({ years, selectedYear: yearInfo.id, selectedYearData: yearInfo },
                () => {
                    //this.getProfile();
                    this.getAllData();
                    this.getCurrentYear();
                    getTaxCalc(this.state.selectedYear, (json) => { 
                      
                        this.setState({ taxCalc: json }) 
                        this.setState({useAgent: json.express_submit_enabled == "Y"})
                    });
                }
            );
        });

        window.onpopstate = (e) => {
            if (e.state) {
                if (this.state.data != null) {
                    this.setState({ data: null })
                }

                if (this.state.addReturn) {
                    this.setState({ addReturn: false })
                }
            }
        };

        if (this.props.location.state) {
            const s = this.props.location.state
            if (s.addReturn) {
                this.setState({addReturn: s.addReturn })
            }
            if (s.addReturn) {
                this.setState({processingSubmission: s.processingSubmission })
            }

        }

    }

    getAllData() {
        this.getReturns();
        this.getProfile();
        this.getFinal();
        this.getSEProfile();
    }

    getProfile() {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${api_url}/getUserProfile.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                json.dob = moment(json.dob, 'DD/MM/YYYY').toISOString()

                this.setState({ profile: json })
            })
    }

    getReturns() {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        const fromDate = this.state.selectedYearData.start_date;
        const toDate = this.state.selectedYearData.end_date + 'T23:59:59:59Z'

        fetch(`https://${api_url}/getTaxSubmissions.php?ts=${timeStamp}&timefrom=${fromDate}&timeto=${toDate}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        this.props.history.push("/logout");
                    }
                } else {
                    this.setState(
                        {
                            returns: json.returns,
                        }
                    );
                }
            });
    }

    getFinal() {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${api_url}/getTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=final`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {

                var new_data = {
                    provisional: false,
                    otherinfo: ""
                };

                const data = JSON.parse(json.data);

                if (data != null) {

                    new_data = {
                        provisional: data.estimated,
                        otherinfo: data.other_information
                    }

                }

                //this.setState({ ...new_data })
                this.setState(Object.assign(this.state, new_data));
            })
    }

    getSEProfile() {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${api_url}/getTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=self_employment`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {

                let new_data = {
                    provisional: false,
                    otherinfo: ""
                };

                const data = JSON.parse(json.data);

                if (data != null) {

                    new_data = {
                        se_box_5_on: data.box_5 !== "",
                        se_box_5: moment(data.box_5, 'DD/MM/YYYY').toISOString(),
                        se_box_3: data.box_3,
                        se_details_1: data.details_1,
                        se_details_2: data.details_2,
                        se_details_3: data.details_3,
                    }

                }

                //this.setState({ ...new_data })
                this.setState(Object.assign(this.state, new_data));
            })
    }

    submitReturn(til = true) {
        // if (!this.state.declare) {
        //     this.setState({ declareError: true });
        //     return;
        // }

        //need to update final first .. 
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        let payload = {
            include: true,
            estimated: this.state.provisional,
            other_information: this.state.otherinfo,
            covid_support: this.state.covidSupport
        };

        //this.setState({ showLoading: true })
        fetch(`https://${api_url}/saveTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=final`, {
            method: "POST",
            headers: { token: token },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {

                //save SE profile .. if we have self employment?
                if ((this.state.taxCalc.trading_income > 0) || (this.state.taxCalc.trading_expenses > 0)) {
                    //we have trading income/expenses so we need to save SE profile

                    let box_5 = "";
                    if (this.state.se_box_5_on) {
                        box_5 = moment(this.state.se_box_5).format('DD/MM/YYYY');
                    }
                    let payload = {
                        include: true,
                        box_1: this.state.profile.name,
                        box_2: this.state.profile.postcode,
                        box_3: this.state.se_box_3,
                        box_5: box_5
                    };

                    fetch(`https://${api_url}/saveTaxData.php?ts=${timeStamp}&tax_year_id=${this.state.selectedYear}&type=self_employment`, {
                        method: "POST",
                        headers: { token: token },
                        body: JSON.stringify(payload)
                    })
                        .then(res => res.json())
                        .then(json => {

                            this.finalFileReturn(til);
                        });

                } else {
                    this.finalFileReturn(til);
                }

                //this.finalFileReturn(til);

            })
    }

    finalFileReturn(til) {
        //now need to submit the return!
        //const test_service = this.props.match.params.name === 'testService' ? 'Y' : 'N'
        const test_service = window.location.href.includes('testService') ? 'Y' : 'N';

        const test_file = til ? 'Y' : 'N';
        const amendment = this.state.amendment ? 'Y' : 'N';
        const data = `test_file=${test_file}&amendment=${amendment}&test_service=${test_service}&utr=${this.state.profile.utr}`;

        let hmrc_username = this.state.hmrcUsername;
        let hmrc_password = this.state.hmrcPassword;
        // if (this.state.useAgent && this.state.taxCalc.express_submit_enabled!='Y') {            
        //     //agent username and password - but we need to check we've authed user ..
        //     hmrc_username = 'untiedpro'
        // }

        const payload = {
            hmrc_username: hmrc_username,
            hmrc_password: hmrc_password,
            express_submit: this.state.useAgent ? 'Y' : 'N'
        }

        localStorage.setItem("HMRCUsername", hmrc_username);

        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        fetch(
            `https://${api_url}/tax/submitXML.php?tax_year_id=${this.state.selectedYear}&ts=${timeStamp}&${data}`,
            {
                method: "POST",
                headers: { token: token },
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(json => {
                Mixpanel.track('submitted_return', { test: til, success: json.success });
                this.setState({
                    til: til,
                    showLoading: false,
                    addReturn: false,
                    data: json.data,
                    report_token: json.report_token,
                    saving: false,
                    heading: "HMRC Response:",
                    success: json.success,
                    response_heading:
                        json.success === "Y"
                            ? til
                                ? "Test Submission Successful"
                                : "Submission Successful"
                            : "Submission Failed",
                    submitIsDone: !til
                });

                //now trigger send email
                if (json.success === "Y" && !til) {

                    fetch(`https://${api_url}/tax/sendPDFEmail.php`, {
                        method: "POST",
                        headers: { token: token },
                        body: JSON.stringify({
                            report_token: json.report_token,
                            tax_year_id: this.state.selectedYear
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            if (json.status === 'success') {
                                this.setState({ sentEmail: true })
                            }
                        });
                }

                //toggle do til if successful TIL submission, so that we can submit live quickly
                if (json.success === "Y" && til) {
                    this.setState({ dotil: false })
                }

            })
    }

    /*
                    this.getReturns();
                    getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
                    this.setState({ addReturn: false, showLoading: false, showSaved: true })
    */

    sendEmail(subject, message) {

        let body = JSON.stringify({ subject: subject, message: message })
        const token = localStorage.getItem("token");

        fetch(`https://${api_url}/sendHelpEmail.php`,
            {
                method: "POST",
                body: body,
                headers: { token: token }
            }).then(res => res.json())
            .then(json => { alert('Thanks, a support ticket has been created and we will get back to you soon!') });
    }


    getCurrentYear() {
        const today = new Date();
        const yearElement = this.state.years.find(element => {
            const sd = new Date(element.start_date);
            const ed = new Date(element.end_date);
            return (today >= sd && today <= ed)
        })
        this.setState({ thisYearData: yearElement })
    }

    handleYearChange = (event) => {
        const yearElement = this.state.years.find(element => {
            return element.id === event.target.value
        })
        Mixpanel.track("changed_year", yearElement);
        this.setState({ selectedYear: event.target.value, selectedYearData: yearElement }, () => {
            this.getAllData();
            getTaxCalc(this.state.selectedYear, (json) => { this.setState({ taxCalc: json }) });
        });
    };

    renderAmount(element) {
        const prefix = element.type === 'CREDIT' ? "+ £" : "£"
        return (
            prefix +
            Number(element.amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
    }

    prettyDateTime(dtstr) {
        return moment(dtstr, 'YYYY-MM-DD HH:mm:ss').format('ddd DD MMM YYYY');
    }

    showButtons() {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol size-sm="3" />
                    <IonCol size-sm="6">
                        <CustomButton
                            onClick={() => {
                                Mixpanel.track("submit_return_start");
                                //window.history.pushState('form', 'Tax Return', '/page/TaxFiling/form');
                                this.setState({ addReturn: true });
                                this.setState({ processingSubmission: true });
                            }}
                            label="Submit Return"
                        />
                    </IonCol>
                    <IonCol size-sm="3" />
                </IonRow>
            </IonGrid>
        )
    }

    showReturns() {
        return (
            <IonList>
                {this.state.returns.map(element => {
                    if (element.tax_year === this.state.selectedYearData.tax_year) {
                        return (
                            <IonItem style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }} key={element.id}>
                                <IonGrid>
                                    <IonRow style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                        <IonCol className="text-center">{this.prettyDateTime(element.submitted)}</IonCol>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>{element.test === "Y" ? "Test" : "Final"}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="text-center">
                                            <a href={`https://pdfe1.untied.io/render_pdf.php?u=https://${api_url}/tax/viewReturnReport.php?t=${element.token}`}>{element.response}</a>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        )
                    }
                })}

            </IonList>
        )
    }

    processTaxSubmission(dotil) {


        //give a warning if trying to submit for the current year?
        if (this.state.selectedYearData.start_date === this.state.thisYearData.start_date) {
            if (!window.location.href.includes('testService')) {
                this.setState({ currentYearError: true });
                return;
            }
        }

        if ((this.state.profile.plan === 1 || this.state.profile.sub_active === 'N') && !dotil) {
            this.setState({ upgradeError: true });
            return;
        }

        if (!this.doValidation()) {
            return
        }

        if (this.state.declare) {

            if (dotil === true) {
                this.setState({ showLoading: true, confirmSubmit: false, dotil: dotil }, () => {
                    this.submitReturn(true);
                })
            } else {

                this.setState({ confirmSubmit: true, dotil: dotil })
            }


        } else {
            this.setState({ declareError: true });
        }
    }

    showSubmitReturnForm() {
        const askSE = ((this.state.taxCalc.trading_income > 0) || (this.state.taxCalc.trading_expenses > 0));
        return (
            <div>
                <IonGrid fixed={true}>

                    <IonRow>
                        <IonCol>
                            <YearSelector onYearChange={(event) => this.handleYearChange(event)}
                                years={this.state.years}
                                selectedYear={this.state.selectedYear}
                                text="Year:"
                                disableField={true}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <TaxSummary taxCalc={this.state.taxCalc} />
                        </IonCol>
                    </IonRow>
                    {this.state.taxCalc.express_submit_enabled !== "Y" ?
                    <IonRow>
                        <IonCol>
                            <h6 className="mt-3">You must have your UTR to submit. You can submit using your <span className="strong-text">HMRC log in</span> (also known as Government Gateway login) or using the <span className="strong-text">untied "express submit"</span> service. <a href="https://help.untied.io/article/59-submitting-your-tax-return" target="_new">Find out more</a></h6>
                        </IonCol>
                    </IonRow>
                    :
                    <IonRow>
                        <IonCol>
                            <h6 className="mt-3">You must have your UTR to submit. Your account is enabled for express submit.</h6>
                        </IonCol>
                    </IonRow>
                    }
                    <IonRow>
                        <IonCol className="text-center">
                            <IonItem className="input-container">
                                <IonLabel position="floating">Submit using:</IonLabel>
                                <IonSelect value={this.state.useAgent} interface="popover"
                                    onIonChange={(event) => {
                                        this.setState({ useAgent: event.target.value })
                                    }
                                    }>
                                    <IonSelectOption value={false}>Your HMRC online account</IonSelectOption>
                                    <IonSelectOption value={true}><span className="notranslate">untied</span> express submit</IonSelectOption>

                                </IonSelect>
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    {this.state.useAgent && this.state.taxCalc.express_submit_enabled != 'Y' && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <p style={{ paddingLeft: 15, fontSize: '14px' }}>Before you can use <span className="notranslate">untied</span> express submit we need you to send us proof of ID.
                                    {" "}<span className="fake-anchor" onClick={() => this.setState({ amlDetails: true })}>Click here for details</span>. 
                                    </p>
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}
                    {/* {this.state.useAgent && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel position="floating">Authorisation code:</IonLabel>
                                    <IonInput type="password" value={this.state.hmrcPassword} onIonChange={e => this.setState({ hmrcPassword: e.detail.value })} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )} */}
                    {!this.state.useAgent && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel position="floating">HMRC Username:</IonLabel>
                                    <IonInput type="text" value={this.state.hmrcUsername === "null" ? "" : this.state.hmrcUsername} onIonChange={e => this.setState({ hmrcUsername: e.detail.value })} />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                    {!this.state.useAgent && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel position="floating">HMRC Password:</IonLabel>
                                    <IonInput type="password" value={this.state.hmrcPassword} onIonChange={e => this.setState({ hmrcPassword: e.detail.value })} />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}

                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel position="floating">Unique Taxpayer Reference: *</IonLabel>
                                <IonInput type="text" value={this.state.profile.utr} onIonChange={e => this.setState({ profile: { utr: e.detail.value } })} />
                                <IonIcon color="primary" className="mr-0" slot="end" icon={informationCircleSharp}
                                    onClick={() => {
                                        this.setState({ showUTRinfo: true })
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel>Test your <span className="strong-text">submission with HMRC</span>. <h6><span className="strong-text red-text">Recommended</span></h6></IonLabel>
                                <IonCheckbox checked={this.state.dotil} onIonChange={e => {
                                    this.setState({ dotil: e.detail.checked })
                                }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol>
                            Tick the boxes that apply:
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel style={{paddingLeft:5}}>I already filed a return for this year but need to amend/update my return.</IonLabel>
                                <IonCheckbox checked={this.state.amendment} onIonChange={e => this.setState({ amendment: e.detail.checked })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    {askSE && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel style={{paddingLeft:5}}>My self employment started after {moment(this.state.selectedYearData.start_date).format("DD/MM/YYYY")}</IonLabel>
                                    <IonCheckbox checked={this.state.se_box_5_on} onIonChange={e => this.setState({ se_box_5_on: e.detail.checked })} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}

                    {askSE && this.state.se_box_5_on && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container" >
                                    <IonLabel style={{paddingLeft:5}} position="stacked">Date self employment started:</IonLabel>
                                    {/* <IonDatetime displayFormat="DD MMM YYYY" placeholder="Select date" value={this.state.se_box_5}
                                        style={{ paddingLeft:5, "--padding-start": 0 }}
                                        min={this.state.selectedYearData.start_date}
                                        max={this.state.selectedYearData.end_date}
                                        onIonChange={e => {console.log(e.detail.value)}} /> */}

                                        <input 
                                            type= "date" 
                                            value = {this.state.se_box_5}
                                            min = {this.state.selectedYearData.start_date}
                                            max = {this.state.selectedYearData.end_date}
                                            style = {{paddingLeft: 5, border: "none", width: "100%", height: "fit-content", font: "400 14px Arial", backgroundColor: "white", color: "black"}}
                                            onChange={e => {
                                                this.setState({ se_box_5: e.target.value })
                                            }} 
                                        />

                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}

                    {askSE && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel style={{paddingLeft:5}}>I changed my name, address or postcode in the last 12 months. (Include details below under "Any other information")</IonLabel>
                                    <IonCheckbox checked={this.state.se_box_3} onIonChange={e => this.setState({ se_box_3: e.detail.checked })} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}

                    {this.state.selectedYearData.tax_year === '2020-2021' && (
                        <IonRow>
                            <IonCol>
                                <IonItem className="input-container">
                                    <IonLabel style={{paddingLeft:5}}>My businesses received coronavirus support payments that have been included as taxable income.</IonLabel>
                                    <IonCheckbox checked={this.state.covidSupport} onIonChange={e => this.setState({ covidSupport: e.detail.checked })} />
                                </IonItem >
                            </IonCol>
                        </IonRow>)}

                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel style={{paddingLeft:5}}>The return includes provisional amounts. <a href="https://help.untied.io/article/36-what-goes-in-the-any-other-information-box" target="_new">Help <IonIcon color="primary" icon={openOutline} /></a></IonLabel>
                                <IonCheckbox checked={this.state.provisional} onIonChange={e => this.setState({ provisional: e.detail.checked })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel position="floating">Any other information:</IonLabel>
                                <IonTextarea placeholder="" value={this.state.otherinfo} onIonChange={e => this.setState({ otherinfo: e.detail.value })} rows={4}></IonTextarea>
                                <IonIcon color="primary" className="mr-0" slot="end" icon={informationCircleSharp}
                                    onClick={() => {
                                        window.open("https://help.untied.io/article/36-what-goes-in-the-any-other-information-box", "_blank");
                                    }}
                                />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="input-container">
                                <IonLabel><span className="strong-text">Check this box to declare that the information provided for this tax return is correct and complete to the best of your knowledge and belief</span>.</IonLabel>
                                <IonCheckbox checked={this.state.declare} onIonChange={e => this.setState({ declare: e.detail.checked })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-sm="4">
                            <CustomButtonInverse
                                onClick={() => {
                                    this.setState({ addReturn: false });
                                    this.setState({ processingSubmission: false });
                                    window.history.pushState('form', 'Tax Return', '/page/TaxFiling');
                                }}
                                label="Cancel"
                              
                                small
                            />
                        </IonCol>
                        <IonCol size-sm="4" />
                        <IonCol size-sm="4">
                            <CustomButton
                                onClick={() => {
                                    this.processTaxSubmission(this.state.dotil)
                                }}
                                label="Submit"
                                small
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow /><IonCol />

                </IonGrid>

                <IonAlert
                    isOpen={this.state.declareError}
                    onDidDismiss={() => this.setState({ declareError: false })}
                    header={'Please confirm'}
                    subHeader={'Declaration required'}
                    message={'In order to submit please check everything is right to the best of your knowledge and check the declaration box .'}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]} />

                <IonAlert
                    isOpen={this.state.amlDetails}
                    onDidDismiss={() => this.setState({ amlDetails: false })}
                    header={'Use Express Submit'}
                    subHeader={'Get a secret code'}
                    message={'If you cannot use your HMRC online account you can send via "untied express submit". To do this ' +
                        'we will need two forms of ID - one with a photo (e.g. passport, or driver\'s license) and a different one with your address. ' +
                        'Please send these as scans or photos to <a href="mailto:aml@untied.io">aml@untied.io</a>. It can take up to 24 hours to process. ' +
                        'We will then give you a secret code you can use to submit your return'}
                    buttons={[
                        {
                            text: 'Translate',           
                            cssClass: 'text-center col-5 btn-translate',
                            handler: blah => {
                                let text = document.body.innerText;
                                let url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');
                                //this.setState({ amlDetails: false })
                            }
                        },
                        {
                            text: 'OK',
                            role: 'OK',
                            cssClass: 'shadow text-center col-5 ion-button-money'
                        }
                    ]}
                />

                <IonAlert
                    isOpen={this.state.currentYearError}
                    onDidDismiss={() => this.setState({ currentYearError: false })}
                    header={`Cannot submit`}
                    subHeader={"The year hasn't ended yet"}
                    message={`You cannot submit a tax return for ${this.state.selectedYearData.explain} as it hasn't finished yet. Please select a different year.`}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />

                <IonAlert 
                    cssClass="utrAlert"
                    isOpen={this.state.showUTRinfo}
                    onDidDismiss={() => {
                    this.setState({ showUTRinfo: false })
                    }}
                    header={"Unique Taxpayer Reference"}
                    subHeader={""}
                    message={'A UTR number is ten digits long, and HMRC uses it to identify you. You will get one when you register for self-employment. If you have an HMRC log in you can find this number in your account. ' +
                    '<br /><br />' + 
                    "Don't have a UTR yet? untied can help you, <a href='https://join.untied.io/' target='_new'>click here</a>"}
                    buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'text-center col-12'
                    }]}
                />

            </div >
        )
    }

    doResponse() {

        if (this.state.data != null) {
            return (
                <div>
                    <IonGrid fixed={true}>
                        {!this.state.submitIsDone &&
                            (<>
                                <YearSelector onYearChange={(event) => this.handleYearChange(event)}
                                    years={this.state.years}
                                    selectedYear={this.state.selectedYear}
                                    text="Year:"
                                    disableField={true}
                                />
                                <IonRow>
                                    <IonCol>
                                        <TaxSummary taxCalc={this.state.taxCalc} />
                                    </IonCol>
                                </IonRow>
                            </>)}

                        {this.state.success === "N" && this.state.data.includes("Authentication Failure") &&
                            (<>
                                <IonRow>
                                    <IonCol>
                                        <h6><span className="submit-error-title">Oops... Something's not quite right.</span></h6>
                                        <div className="submit-error-image-container mt-3"></div>    
                                    </IonCol>
                                </IonRow>
       
                                <IonRow>
                                    <IonCol>

                                    <h6 className="mt-3">Uh oh... Your tax submission <span className="strong-text">was NOT successful! HMRC reported an{" "} 
                                    <span class="fake-anchor" onClick={() => { this.props.history.push('/page/SubmitAuthError', {report_token: this.state.report_token}) }}>authentication error</span></span></h6>


                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol size-sm="3" />
                                    <IonCol size-sm="6">
                                        <CustomButton
                                            label="What can I do?"
                                            onClick={ (e) => {
                                                this.props.history.push('/page/SubmitAuthError', {report_token: this.state.report_token})
                                                e.preventDefault()
                                            } }
                                            small
                                        />
                                    </IonCol>
                                    <IonCol size-sm="3" />
                                </IonRow>                                

                            </>)
                        }

                        {this.state.success === "N" && !this.state.data.includes("Authentication Failure") &&
                            (<>
                                <IonRow>
                                    <IonCol>
                                        <h6><span className="submit-error-title">Oops... Something's not quite right.</span></h6>
                                        <div className="submit-error-image-container mt-3"></div>

                                        <IonRow>
                                    <IonCol>

                                    <h6 className="mt-3">Uh oh... Your tax submission <span className="strong-text">was NOT successful! HMRC reported the following:</span></h6>


                                    </IonCol>
                                </IonRow>

                                        <IonCard color="black">
                                            <IonCardHeader>
                                                <IonCardTitle className="text-center">                                                    
                                                    <h6 className="d-inline-block">HMRC Response</h6>
                                                </IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                {this.state.data}
                                            </IonCardContent>
                                        </IonCard>
                                        <h6 className="mt-3">If you need help, <span className="strong-text fake-anchor" onClick={() => this.sendEmail("Submission Error", this.state.data)}>click here to send us the response</span>.</h6>
                                    </IonCol>
                                </IonRow>
                            </>)
                        }

                        {!this.state.submitIsDone && this.props.subscribedWhileSubmit &&
                            (<>
                                <IonRow>
                                    <IonCol>
                                        <h6><span className="strong-text strong-header">Last step</span></h6>
                                        <h6 className="mt-4">Now that you subscribed click <span className="strong-text">"Submit now" to finalise your tax return</span>.</h6>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-sm="4">
                                        <CustomButton
                                            label="Download report"
                                            linkTo={`https://pdfe1.untied.io/render_pdf.php?u=https://${api_url}/tax/viewReturnReport.php?t=${this.state.report_token}`}
                                            small
                                        />
                                    </IonCol>
                                    <IonCol size-sm="4" />
                                    <IonCol size-sm="4">
                                        <CustomButton
                                            onClick={() => {
                                                this.processTaxSubmission(this.state.dotil)
                                            }}
                                            label="SUBMIT NOW"
                                            small
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow /><IonCol />
                            </>)
                        }

                        {!this.state.submitIsDone && this.state.success === "Y" && !this.props.subscribedWhileSubmit &&
                            (<>
                                <IonRow>
                                    <IonCol>
                                        <h6><span className="strong-text strong-header page-header-title">Almost there!! 😁</span></h6>

                                        <div className="submit-success-image-container mt-3"></div>

                                        <h6 className="mt-4">Your <span className="strong-text">test submission</span> was successful!</h6>

                                        <IonCard color="black">
                                            <IonCardHeader>
                                                <IonCardTitle className="text-center">                                                  
                                                    <h6 className="d-inline-block">HMRC Response</h6>
                                                </IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                {this.state.data}
                                            </IonCardContent>
                                        </IonCard>



                                        <h6 className="mt-4 strong-text">Remember this was a test! To actually file your taxes with HMRC you need to click the button "Submit now".</h6>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-sm="3" />
                                    <IonCol size-sm="6">
                                        <CustomButton
                                            onClick={() => {
                                                this.processTaxSubmission(false);

                                            }}
                                            label="SUBMIT NOW"
                                            small
                                        />
                                    </IonCol>
                                    <IonCol size-sm="3" />
                                </IonRow>
                                <IonRow /><IonCol />
                            </>)
                        }

                        {false && !this.state.submitIsDone && this.state.success === "Y" && this.state.til && (
                            <IonRow>
                                <IonCol size-sm="3" />
                                <IonCol size-sm="6">
                                    <CustomButton
                                        onClick={() => {

                                            if (!this.doValidation()) {
                                                return;
                                            }
                                            this.doSubmit(false);
                                        }}
                                        label="Submit Live Return"
                                    />
                                </IonCol>
                                <IonCol size-sm="3" />
                            </IonRow>
                        )}

                        {false && !this.state.submitIsDone && this.state.success === "Y" && !this.state.til && (
                            <IonRow>
                                <IonCol size-sm="3" />
                                <IonCol size-sm="6">
                                    <CustomButton
                                        onClick={() => {
                                            window.location = '/page/TaxFiling';
                                        }}
                                        label="Done"
                                    />
                                </IonCol>
                                <IonCol size-sm="3" />
                            </IonRow>
                        )}

                        {this.state.submitIsDone && this.state.success === "Y" &&
                            (<>
                                <IonRow>
                                    <IonCol>

                                 
                                        <div className="submit-congrats-image-container mt-3"></div>
                                        <h6 className="mt-4"><span className="page-header-title" style={{fontSize:20}}>You filed your taxes successfully!</span></h6>

                                        <IonCard color="black">
                                            <IonCardHeader>
                                                <IonCardTitle className="text-center">                                                   
                                                    <h6 className="d-inline-block">HMRC Response</h6>
                                                </IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                {this.state.data}
                                            </IonCardContent>
                                        </IonCard>

                                        <p>We sent you an email with a copy of your report and next steps after filing your taxes.</p>
                                        <p>If you liked using untied lite <a href="https://www.reviews.co.uk/company-reviews/store/www.untied.io" target="_new">let us know</a>. </p>

                                        <div className="mt-2">
                                            <CustomButton
                                                label="Download report"
                                                linkTo={`https://pdfe1.untied.io/render_pdf.php?u=https://${api_url}/tax/viewReturnReport.php?t=${this.state.report_token}`}
                                                small
                                            />
                                        </div>

                                      
                                        <div className="mt-4">
                                            <CustomButton
                                                label="Make me feel good!" 
                                                linkTo="/page/Deserved"                                               
                                            />
                                        </div>

                                    </IonCol>
                                </IonRow>
                                <IonRow /><IonCol />
                            </>)}

                    </IonGrid>
                </div>
            )
        } else {
            return (
                <div />
            )
        }
    }

    doValidation() {

        if (this.state.profile.utr === "" || this.state.profile.utr === null) {
            this.setState({ showLoading: false, utrError: true })
            return false;
        }

        if (this.state.profile.name === "" || this.state.profile.postcode === "" || this.state.profile.address === "" ||
            this.state.profile.name === null || this.state.profile.postcode === null || this.state.profile.address === null) {
            this.setState({ showLoading: false, addressError: true })
            return false;
        }

        if (this.state.useAgent && (this.state.taxCalc.express_submit_enabled !== "Y")) {
            this.setState({ showLoading: false, expressSubmitError: true })
            return false;
        }


        if (!this.state.useAgent && (this.state.hmrcPassword === "" || this.state.hmrcUsername === "")) {
            this.setState({ showLoading: false, authError: true })
            return false;
        }

        if (this.state.se_box_5_on) {
            console.log(this.state.se_box_5)
            const timestamp = Date.parse(this.state.se_box_5);
            console.log(timestamp)

            if (isNaN(timestamp) == true) {
                this.setState({ showLoading: false, startDateError: true })
                return false;
            } else {
                const start = Date.parse(this.state.selectedYearData.start_date)
                const end = Date.parse(this.state.selectedYearData.end_date)
                if (timestamp > end || timestamp < start) {
                    this.setState({ showLoading: false, startDateError: true })
                    return false;
                }
            }
        }

        return true
    }

    doSubmit(til) {

        if (this.state.taxCalc) {
            if (til) {
                this.setState({ showLoading: true })
                this.submitReturn(this.state.dotil);
            } else {
                this.setState({ confirmSubmit: true });
            }
        }
    }

    render() {

        const headerLabels = [
            {
                label: "Submit",
                active: !this.state.processingSubmission
            },
            {
                label: "Submitting your return",
                active: this.state.processingSubmission && (this.state.success === undefined || this.state.success === "N")
            },
            {
                label: "Finalising your tax return",
                active: !this.state.submitIsDone && this.state.success === "Y"
            },
            {
                label: "Congratulations!",
                active: this.state.submitIsDone
            },
        ];
        return (
            <IonPage cache-view="false" className="selectable">
                <NavBar />

                <IonContent>
                    <PageHeader label={headerLabels.filter((item) => (item.active))[0].label} />
                    {!this.state.processingSubmission && <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
                        <IonRow>
                            <IonCol>
                                <p className="break-line">There are two ways to submit a tax return with untied:</p>


                                <Collapsible
                                    trigger={(
                                        () => (
                                            <div className="mt-3 ml-1">
                                                1. Use your HMRC login<i className={!this.state.itemTop ? "collapsed" : "expanded"} />
                                                
                                            </div>
                                        )
                                    )()}
                                    containerElementProps={{ id: "itemTop" }}
                                    onOpen={() => {
                                        this.setState({ itemTop: true });
                                    }}
                                    onClose={() => {
                                        this.setState({ itemTop: false });
                                    }}
                                >
                                    <div>
                                        <p> (also known as a Government Gateway login).</p>
                                    </div>
                                </Collapsible>

                                {/* <h6>1. Use your <span className="strong-text">HMRC login</span> (also known as a Government Gateway login). This is the quickest and simplest way.</h6> */}
                                {/* <h6>2. Use the <span className="strong-text">untied "express submit" service</span>. If you don’t have an HMRC login, don’t worry. Just send <span className="strong-text">two forms of ID</span> to <a href="mailto:help@untied.io"><span className="strong-text">aml@untied.io</span></a> – one ID with a photo (eg passport) and a different one with your address (eg driving licence). We will then enable express submit on your account. <span className="strong-text">This can take 24 hours to process, so it’s good to send us your documents early!</span></h6> */}

                                <Collapsible
                                    trigger={(
                                        () => (
                                            <div className="mt-1 ml-1">
                                                2. Use untied express submit without needing your own HMRC login. <span className="fake-anchor">Tell me more</span><i className={!this.state.itemBottom ? "collapsed" : "expanded"} />                                                
                                            </div>
                                        )
                                    )()}
                                    containerElementProps={{ id: "itemBottom" }}
                                    onOpen={() => {
                                        this.setState({ itemBottom: true });
                                    }}
                                    onClose={() => {
                                        this.setState({ itemBottom: false });
                                    }}
                                >
                                    <div>
                                        <p>
                                        This is the quickest and simplest way. Just send two forms of ID to <a href="mailto:aml@untied.io">aml@untied.io</a> – one ID with a photo (eg passport) and a different one with your address (eg driving licence). We will then enable express submit on your account. 
                                        <span className="strong-text">This can take 24 hours to process, so it’s good to send us your documents early!</span>


                                        </p>

                                    </div>
                                </Collapsible>

                                <p className="mt-4">If you have any problems submitting your tax return, we are here to help.</p>

                                <p><span style={{fontStyle:'italic', color:'#4A4A4A', fontSize: 14}}>Please note that to keep the process simple, we don't allow you to top up National Insurance contributions. If you want to do this, you will need to contact HMRC separately.</span></p>
                            </IonCol>
                        </IonRow>
                        <YearSelector onYearChange={(event) => this.handleYearChange(event)} years={this.state.years} selectedYear={this.state.selectedYear} />
                        <IonRow>
                            <IonCol>
                                <TaxSummary taxCalc={this.state.taxCalc} />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                {!this.state.addReturn && this.state.data === null && this.showButtons()}
                                {!this.state.addReturn && this.state.data === null && this.showReturns()}
                            </IonCol>
                        </IonRow>
                    </IonGrid>}

                    {this.state.addReturn && this.state.data == null && this.showSubmitReturnForm()}
                    {this.doResponse()}

                    <IonAlert
                        isOpen={this.state.confirmSubmit}
                        //isOpen={false}
                        onDidDismiss={() => this.setState({ confirmSubmit: false })}
                        header={'Confirm Submission!'}
                        message={`You are about to submit a live tax return for ${this.state.selectedYearData.explain}. Are you ready?`}
                        buttons={[
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'shadow text-center col-5 alert-cancel-button',
                                handler: blah => {
                                    this.setState({ confirmSubmit: false })
                                }
                            },
                            {
                                text: 'Yes, submit',
                                cssClass: 'shadow text-center col-5',
                                handler: () => {
                                    this.setState({ showLoading: true, confirmSubmit: false });
                                    this.submitReturn(false);
                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.upgradeError}
                        onDidDismiss={() => this.setState({ upgradeError: false })}
                        header={'Your plan'}
                        subHeader={'A subscription is required'}
                        message={'A paid subscription is required to submit a live tax return. "Test-in-live" submissions can be done with the free plan by checking the "File test submission" box. Do you want to view upgrade options now?'}
                        buttons={[
                            {
                                text: "No",
                                role: 'cancel',
                                cssClass: 'text-center col-5 alert-cancel-button'
                            },
                            {
                                text: 'Yes',
                                // cssClass: 'shadow text-center col-5',
                                cssClass: "ion-button-money col-5",
                                handler: () => {
                                    this.props.history.replace("/page/Plan?fromReturn");
                                }
                            }
                        ]}
                    />

                    <IonLoading
                        isOpen={this.state.showLoading}
                        onDidDismiss={() => this.setState({ showLoading: false })}
                        message={'Please wait...'}
                        duration={40000}
                    />

                    <IonAlert
                        isOpen={this.state.sentEmail}
                        onDidDismiss={() => this.setState({ sentEmail: false })}
                        header={'Success!'}
                        subHeader={'Check your email'}
                        message={'We have sent a PDF copy of your tax return by email. You can also download a copy from this page.'}
                        buttons={[{
                            text: 'OK',
                            role: 'OK',
                            cssClass: 'shadow text-center col-12'
                        }]}
                    />

                    <IonAlert
                        isOpen={this.state.utrError}
                        onDidDismiss={() => this.setState({ utrError: false })}
                        header={'Unique Tax Reference'}
                        subHeader={'Your UTR is missing'}
                        message={'We cannot submit without a valid UTR (Unique Tax Reference). Please enter your UTR in the ' +
                            '<a href="/page/Profile">Profile screen</a>.'}
                        buttons={[
                            {
                                text: 'Translate',                              
                                cssClass: 'text-center col-5 btn-translate',
                                handler: blah => {
                                    let text = document.body.innerText;
                                    let url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                    window.open(url, '_untiedTranslater');
                                    //this.setState({ amlDetails: false })
                                }
                            },
                            {
                                text: 'OK',
                                role: 'OK',
                                cssClass: 'shadow text-center col-5 ion-button-money'
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.authError}
                        onDidDismiss={() => this.setState({ authError: false })}
                        header={this.state.useAgent ? 'Password Error' : 'Username and Password'}
                        subHeader={'Missing credentials'}
                        message={this.state.useAgent ? 'Please enter the authorisation code received from untied.' : 'Please check your HMRC username and password'}
                        buttons={[
                            {
                                text: 'Translate',                    
                                cssClass: 'text-center col-5 btn-translate',
                                handler: blah => {
                                    var text = document.body.innerText;
                                    var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                    window.open(url, '_untiedTranslater');
                                    //this.setState({ amlDetails: false })
                                }
                            },
                            {
                                text: 'OK',
                                role: 'OK',
                                cssClass: 'shadow text-center col-5  ion-button-money'
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.expressSubmitError}
                        onDidDismiss={() => this.setState({expressSubmitError: false })}
                        header={'Express Submit not enabled'}
                        subHeader={''}
                        message={'Express Submit is not enabled for your account, please contact us or try using your HMRC login details.'}
                        buttons={[
                            {
                                text: 'Translate',
                                cssClass: 'text-center col-5 btn-translate',
                                handler: blah => {
                                    var text = document.body.innerText;
                                    var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                    window.open(url, '_untiedTranslater');
                                    //this.setState({ amlDetails: false })
                                }
                            },
                            {
                                text: 'OK',
                                role: 'OK',
                                cssClass: 'shadow text-center col-5 ion-button-money'
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.addressError}
                        onDidDismiss={() => this.setState({ addressError: false })}
                        header={'Name & Address Error'}
                        subHeader={'Missing details'}
                        message={'Please check that you have entered your name, address and postcode in the <a href="/page/Profile">Profile screen</a>.'}
                        buttons={[
                            {
                                text: 'Translate',
                                cssClass: 'text-center col-5 btn-translate',
                                handler: blah => {
                                    var text = document.body.innerText;
                                    var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                    window.open(url, '_untiedTranslater');
                                    //this.setState({ amlDetails: false })
                                }
                            },
                            {
                                text: 'OK',
                                role: 'OK',
                                cssClass: 'shadow text-center col-5 ion-button-money'
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.startDateError}
                        onDidDismiss={() => this.setState({ startDateError: false })}
                        header={'Start date error'}
                        subHeader={'Invalid self-employment start date'}
                        message={`Your business start date should be a valid date between ${this.prettyDateTime(this.state.selectedYearData.start_date)} and ${this.prettyDateTime(this.state.selectedYearData.end_date)}`}
                        buttons={[
                            {
                                text: 'Translate',
                                cssClass: 'text-center col-5 btn-translate',
                                handler: blah => {
                                    var text = document.body.innerText;
                                    var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                    window.open(url, '_untiedTranslater');
                                    //this.setState({ amlDetails: false })
                                }
                            },
                            {
                                text: 'OK',
                                role: 'OK',
                                cssClass: 'shadow text-center col-5 ion-button-money'
                            }
                        ]}
                    />

                    <div style={{height:40}} />

                </IonContent >
            </IonPage >
        );
    }
}


export default Returns;

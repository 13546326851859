import { IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';
import React, { Component } from "react";
import { CustomButton, NavBar, PageHeader } from "../components/Common"
import { api_url } from "../App"
import { Dots } from "react-activity";
import './Page.scss';

const partnerID = "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a";

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            subscription: { plan: 1, in_trial: false, trial_expires: "", store: "" },
            ready: true
        };
    }

    componentDidMount() {
        this.getSubscription();

        // const script = document.createElement("script");

        // script.src = "https://cdn.paddle.com/paddle/paddle.js";
        // script.async = true;

        // document.body.appendChild(script);


        // const s = document.createElement('script2');
        // s.type = 'text/javascript';
        // s.async = true;
        // s.innerHTML = "Paddle.Setup({ vendor: 120258 });";
        // document.body.appendChild(s);

    }


    waitForPaddleFrame() {
        if (document.getElementsByClassName('paddle-frame').length > 0) {
            document.getElementsByClassName('paddle-frame')[0].style.position = 'fixed';
            console.log('found it')
            return;
        }
        else {
            setTimeout(() => {
                console.log('looking again')
                this.waitForPaddleFrame();
            }, 500);
        }
    }

    // openCheckout = () => {

    //     console.log('opening')
    //     return;
    //     this.setState({ ready: false });

    //     const guid = localStorage.getItem("guid");
    //     const email = localStorage.getItem("username");
    //     window.Paddle.Checkout.open({
    //         product: 620338, passthrough: guid, email: email,
    //         successCallback: () => { setTimeout(function () { window.location.reload(false); }, 5000) },
    //         closeCallback: () => { this.setState({ ready: true }) }
    //     });

    //     console.log('waiting')
    //     this.waitForPaddleFrame();
    // }

    getSubscription() {
        let timeStamp = Math.floor(Date.now() / 1000);
        let token = localStorage.getItem("token");

        fetch(`https://${api_url}/getUserSubscription.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        this.props.history.replace("/logout/logout");
                    }
                } else {

                    this.setState({
                        subscription: json
                    });

                    if (json.offercode && json.offercode!="") {
                        //get coupon info 
                        fetch(`https://${api_url}/paddle/getCouponInfo.php?ts=${timeStamp}&plan=630468&coupon=${json.offercode}`, {
                            method: "GET",
                            headers: { token }
                        })
                            .then(res => res.json())
                            .then(json => {                                   
                                    this.setState({monthlyDiscount:json.discount})
                            })
                        //get coupon info 
                        fetch(`https://${api_url}/paddle/getCouponInfo.php?ts=${timeStamp}&plan=630467&coupon=${json.offercode}`, {
                            method: "GET",
                            headers: { token }
                        })
                            .then(res => res.json())
                            .then(json => {
                                this.setState({annualDiscount:json.discount})
                            })                                

                    } 

                }
            });
    }


    prettyDate(dtstr) {
        if (dtstr === undefined) { return "" }
        let ds = "";
        if (dtstr !== "") {
            //ds = Date.parseExact(dtstr.substring(0, 10), "yyyy-MM-dd");
            ds = new Date(dtstr.substring(0, 10)).toDateString();
        }
        return ds;
    }

    yyyymmdd() {
        function twoDigit(n) {
            return (n < 10 ? "0" : "") + n;
        }
        var now = new Date();
        return (
            "" +
            now.getFullYear() +
            "-" +
            twoDigit(now.getMonth() + 1) +
            "-" +
            twoDigit(now.getDate())
        );
    }

    doPurchase(iap_item) {
        const body = JSON.stringify({
            email: localStorage.getItem("username"),
            start_date: this.yyyymmdd(),
            plan: "3",
            sub_sku: (iap_item === 630467 || iap_item === 630445) ? 'untiedy1' : 'untiedm1',
            store: "untied",
            app: 'gig'
        });

        console.log(body)
        console.log(partnerID)

        let timeStamp = Math.floor(Date.now() / 1000);
        fetch(
            `https://${api_url}/addUserSubscription.php?ts=${timeStamp}&duration=year`,
            {
                method: "POST",
                body: body,
                headers: { partnerId: partnerID }
            }
        )
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if (window.location.href.indexOf('fromReturn') > 0) {
                    this.props.history.replace("/page/TaxFiling");
                } else {
                    window.location.reload(false)
                }
            });

    }


    openCheckout = (sku) => {

        // if (window.location.href.indexOf('fromReturn') > 0) {
        //     this.props.history.push("/page/TaxFiling");
        // }
        // return;
        let coupon = ""
        if (this.state.subscription.offercode) {
            coupon = this.state.subscription.offercode.toLowerCase() != 'deliveroo15' ? this.state.subscription.offercode : ""
        }
        const email = localStorage.getItem("username");
        window.Paddle.Checkout.open({
            product: sku, email: email, coupon: coupon, 
            successCallback: () => {
                // setup plan .. 
                this.doPurchase(sku)
            },
            closeCallback: () => {
                this.setState({ ready: true });
            }
        });

        this.waitForPaddleFrame();
    };

    render() {

        let update_text = "";
        if (this.state.subscription.store === "play_store") {
            update_text = "To manage your subscription please visit the Google Play Store";
        }
        if (this.state.subscription.store === "app_store") {
            update_text = "To manage your subscription please visit the Apple App Store";
        }

        const isDeliveroo = this.state.subscription.offercode && this.state.subscription.offercode.toLowerCase() === 'deliveroo15';
        //const isDeliveroo = this.state.subscription.offercode === 'deliveroo15';
        const offercode = this.state.subscription.offercode
        const annualDiscount = this.state.annualDiscount

        return (

            <IonPage cache-view="false" className="selectable">
                <NavBar label="Your untied plan" />

                <IonContent>
                    <PageHeader label="Your untied plan" />

                    <IonGrid className="ml-2 mr-2 m-auto" fixed={true}>
                        {this.state.subscription.in_trial === true /*this.state.subscription.plan == 1*/ && (
                            <IonRow>
                                <IonCol>
                                    {/* <h6>You are on a FREE TRIAL with untied.</h6> */}

                                    <h6 className="mt-4">You are currently using untied lite on a free trial with access to all features, including a test submission of your tax return to HMRC.</h6>
                                    <h6 className="mt-4">To fully submit your tax return to HMRC you need to purchase an untied subscription.</h6>

                                    {!this.state.ready && (
                                        <Dots />
                                    )}

                                    <h6 className="mt-4 strong-text">Purchase a subscription</h6>

                                    {isDeliveroo && (

                                        <div className="text-center mt-4">
                                            <IonButton size="large"
                                                className="subscription-button text-lowercase"
                                                onClick={() => this.setState({ btn1Selected: true, btn2Selected: false }, () => this.openCheckout(630445))}

                                            >
                                                <IonLabel className="text-center">
                                                    <h6>deliveroo rider offer</h6>
                                                    <h6>Use my £15/year</h6>                                           
                                                </IonLabel>
                                            </IonButton>
                                        </div>
                                    )}

                                    {!isDeliveroo && (
                                        <div className="text-center mt-4">
                                            {/* <div>
                                                <CustomButton
                                                    onClick={() => this.setState({ btn1Selected: true, btn2Selected: false }, () => this.openCheckout(630468))}
                                                    label="£4.99/ monthly"
                                                    big
                                                />
                                            </div> */}
                                            <div className="mt-3">
                                                <CustomButton
                                                    onClick={() => this.setState({ btn1Selected: false, btn2Selected: true }, () => this.openCheckout(630467))}
                                                    label="£49.99/ yearly"
                                                    big
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!isDeliveroo && annualDiscount!=null && annualDiscount!=0 && offercode && offercode!=="" && (                                                                
                                        <p style={{textAlign:'center', paddingLeft:50, paddingRight:50, paddingTop:10}}>Your discount code (<strong>{offercode}</strong>) 
                                            will automatically be applied during checkout. This gives you <strong>{annualDiscount} off the yearly plan price shown above</strong>.</p>
                                                             
                                    )}
                                    
                                    <h6 className="mt-5">If you made <span className="strong-text">money in other ways</span>, for example, renting out a property or you want to take advantage of additional benefits like: <br/><br/>
                                        <ul>
                                            <li>Smart transaction tagging</li>
                                            <li>Automatic journey logging</li>
                                        </ul> 
                                    you need to upgrade to <span className="strong-text">untied Pro</span>.</h6>
                                    <h6>Contact us at <a href="mailto:upgrade@untied.io"><span className="strong-text">upgrade@untied.io</span></a> about a special offer for untied Pro today.</h6>

                                    <h6 className="text-center">
                                        <a href={`https://www.untied.io/rp/${localStorage.getItem("username")}`} target="_referrals">Refer friends and earn cash</a>
                                    </h6>
                                </IonCol>
                            </IonRow>
                        )}

                        {this.state.subscription.plan !== '1' && this.state.subscription.in_trial === false && (
                            <IonRow>
                                <IonCol>
                                    <h6>Your plan: {this.state.subscription.plan === '3' ? "Lite" : "Pro"}</h6>
                                    <h6 className="mt-4">Purchased on: {this.prettyDate(this.state.subscription.purchase_date)}</h6>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol>
                                <h6>{update_text}</h6>
                            </IonCol>
                        </IonRow>

                        {this.state.subscription.store && this.state.subscription.store === "untied" && this.state.subscription.cancels_on === "" && (
                            <IonRow>
                                <IonCol>
                                    <h6><a href={this.state.subscription.cancel_url}>Cancel Your Subscription</a></h6>
                                    <h6 className="mt-4"><a href={this.state.subscription.update_url}>Update Your Payment Details</a></h6>
                                    <h6 className="mt-4">You can see more Pro features <a href="https://www.untied.io/#features" target="_blank" rel="noopener noreferrer">here</a> or contact us at <a href="mailto:upgrade@untied.io"><span className="strong-text">upgrade@untied.io</span></a> about a special offer for untied Pro today.</h6>

                                </IonCol>
                            </IonRow>
                        )}

                        {this.state.subscription.store && this.state.subscription.store.toLowerCase() === "untied" && this.state.subscription.cancels_on !== "" && (
                            <IonRow>
                                <IonCol>
                                    <h6>Your subscription cancels on {this.prettyDate(this.state.subscription.cancels_on)}</h6>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonGrid>
                </IonContent >
            </IonPage >
        );
    }
}

export default Plan;